<template>
    <validation-observer ref="observer" v-slot="{ handleSubmit }" v-if="currentUser">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <b-container class="page " v-if="example && account && countries">
                <b-row class="titre mb-2" v-if="example.topic.domain">
                    <div v-html="$t('domaines.' + example.topic.domain.title)"/>
                </b-row>
                <b-row class="stitre font-weight-bold">
                    <div v-html="$t('domaines.' + example.topic.title)"/>
                </b-row>
                <b-row class="mb-2">
                    <div v-html="$t('examples.' + example.title)"/>
                </b-row>
                <b-row class="mb-2 calcul">
                    <example-table
                        :example="example"
                        :country="account.countryProfil ? account.countryProfil : defaultCountry"
                        :account="account"
                    />
                </b-row>
                <b-row class="mb-2" v-if="account.lastOrder && account.lastOrder.price && account.lastOrder.price.name !== 'free' && account.lastOrder.price.name !='solo'">
                    <b-col sm="8" md="6">
                        <b-row>
                            <b-select
                                size="sm"
                                v-model="countryCompare"
                            >
                                <b-form-select-option :value="null">{{ $t('website.comparer') }}</b-form-select-option>
                                <b-form-select-option
                                    v-for="country in countries"
                                    :key="country.id"
                                    :value="country"
                                    v-if="!account.countryProfil || country.code !== account.countryProfil.code"
                                >
                                    {{ $t('countries.' + country.code) }}
                                </b-form-select-option>
                            </b-select>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="mb-2 calcul" v-if="countryChanged">
                    <example-table
                        :example="example"
                        :country="countryCompare"
                        :account="account"
                    />
                </b-row>
                <b-row class="mt-4 mb-2" v-if="account">
                    <b-col>
                        <b-row class="stitre font-weight-bold" v-html="$t('website.calculerhonorairescreation')"/>
                        <b-row class="left"><span class="mt-1">|</span> <span v-html="$t('website.enquete')" class="ml-1 mr-1 mt-1"/>
                            <b-checkbox v-model="figure" @change="toggleFigure"/> <span class="mt-1">|</span>
                            <router-link :to="{ name: 'profil', params: {lng: lng}}" class="ml-1 mr-1 mt-1">
                                <span v-html="$t('website.reglages')" class="mt-1"/>
                            </router-link>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <b-row>
                            <example-calcul
                                :example="example"
                                :account="account"
                            />
                        </b-row>
                    </b-col>
                </b-row>
            </b-container>
            <bottom-actions
                :button-text="$t('website.03_bt_calculer_droits')"
                :button-text2="$t('website.03_bt_devis_direct')"
                :set-action="setAction"
            />
        </b-form>
    </validation-observer>
</template>

<script>
    import axios from "axios";
    import BottomActions from "../../../components/footer/BottomActions";
    import ExampleTable from "../../../components/front/ExampleTable";
    import ExampleCalcul from "../../../components/front/calcul/ExampleCalcul";
    import {serverBus} from "../../../../app";

    export default {
        name: "Calcul",
        components: {ExampleCalcul, ExampleTable, BottomActions},
        data() {
            return {
                account: null,
                example: null,
                countryCompare: null,
                countries: null,
                defaultCountry: null,
                buttonName: null,
                figure: true,
            }
        },
        computed: {
            lng() {
                return this.$store.state.actLng
            },
            currentUser() {
                return this.$store.state.currentUser
            },
            countryChanged() {
                return this.countryCompare
            },
        },
        watch: {
            $route(to, from) {
                //console.log('watch route')
                this.getExample()
            },
            currentUser(newUser, oldUser) {
                this.getAccount()
            },
        },
        mounted() {
            this.$store.commit('setElement', {key: 'calcul', value: null})
            if(!this.$store.state.currentUser) {
                this.$router.push({name: 'home', params: {lng: this.$store.state.actLng}})
            }
            this.getAccount()
        },
        methods: {
            getAccount() {
                this.$getAccount(this.$store.state.apiUrl, this.$store.state.accessToken, this.$store.state.currentUser).then(
                    data => {
                        this.account = data
                        this.figure = (this.account.ckFigure === 1)
                        this.toggleFigure()
                        this.getExample()
                        this.getCountries()
                        this.getFrCountry()
                        this.clearCalcul()
                    }
                )
            },
            getExample() {
                this.$store.commit("setDomainStep", 3)
                this.$store.commit("setDomains", true)

                //console.log('example ' + this.$route.params.id)

                const url = this.$store.state.apiUrl + '/api/examples/' + this.$route.params.id
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.example = response.data
                        this.$checkAccessPage(this.account, this.example)
                    }
                )
            },
            getCountries() {
                const url = this.$store.state.apiUrl + '/api/countries?exists[group]=true&pagination=false'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.countries = response.data["hydra:member"]
                    }
                )
            },
            getFrCountry() {
                const url = this.$store.state.apiUrl + '/api/countries?exists[group]=true&pagination=false&code=fr'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.defaultCountry = response.data["hydra:member"][0]
                    }
                )
            },
            setAction(name) {
                this.buttonName = name
            },
            onSubmit() {
                this.$store.commit("setElement", {'key': 'example', 'value': this.example})
                let route
                if(this.buttonName === 'buttonText') {
                    route = 'droits'
                }
                if(this.buttonName === 'buttonText2') {
                    route = 'devis'
                }
                this.$router.push({name: route, params: {lng: this.$store.state.actLng}})
            },
            clearCalcul() {
                let calcul = this.$store.state.calcul
                if(calcul) {
                    calcul['exploitation'] = null
                    calcul['redevance'] = null
                    calcul['option'] = null
                    calcul['option2'] = null
                    this.$store.commit('setElement', {'key': 'calcul', 'value': calcul})
                }
            },
            toggleFigure() {
                this.$store.commit("setElement", {'key': 'figure', 'value': this.figure})
                serverBus.$emit('figure', this.figure)
            },
        }
    }
</script>

<style scoped>
    .stitre {
        font-size: 15px;
        line-height: 17px;
    }
</style>
