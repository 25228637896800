<template>
    <b-table-simple class="text-center" v-if="country">
        <b-thead>
            <b-tr>
                <b-th class="style4 text-uppercase font-weight-bold">
                    {{ $t('countries.' + country.code) }}
                </b-th>
                <b-th class="style4 font-weight-bold"><span v-html="$t('website.00j_compare_localisation')"/></b-th>
                <b-th class="style2"><span v-html="$t('website.03_tab_indep1')"/></b-th>
                <b-th class="style2"><span v-html="$t('website.03_tab_indep2')"/></b-th>
                <b-th class="style3"><span v-html="$t('website.03_tab_indep3')"/></b-th>
                <b-th class="style2"><span v-html="$t('website.03_tab_societe')"/></b-th>
                <b-th class="style2"><span v-html="$t('website.03_tab_specialiste')"/></b-th>
                <b-th class="style4"><span v-html="$t('website.03_tab_tauxmoy')"/></b-th>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr v-for="domain in domains" :key="domain.id" v-if="domain.menu">
                <b-td class="style1"><span v-html="$t('domaines.'+domain.title)" class="font-weight-bold"/></b-td>
                <b-td class="style1 cells">
                    <discipline-cells
                        :title1="$t('website.03_tab_gdesvilles')"
                        :title2="$t('website.03_tab_ptvilles')"
                    />
                </b-td>
                <b-td class="cells text-nowrap" v-for="n in 5" :class="(n==3) ? 'style3' : 'style2'" :key="n">
                    <discipline-cells
                        :title1="Math.round(country.group.coef * domain.rates[0][n-1]) + ' ' + currencyHour"
                        :title2="Math.round(country.group.coef*domain.rates[1][n-1]) + ' ' + currencyHour"
                    />
                </b-td>
                <b-td class="style4">
                    <span
                        v-html="Math.round(country.group.coef*domain.moyenne) + ' ' + currencyHour"
                    />
                </b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>
    import DisciplineCells from "./DisciplineCells";
    import domainsJson from '../../../files/domains.json'

    export default {
        name: "DisciplineTable",
        components: {DisciplineCells},
        props: ["country", "currency"],
        data() {
            return {
                domains: domainsJson
            }
        },
        computed: {
            currencyHour() {
                return (this.currency ? this.currency.symbol : '€') + this.$t('website.03_tab_heure')
            }
        },
        mounted() {
            this.$store.commit("setDomains", true)
        },
    }
</script>

<style scoped>
    table {
        border-spacing: 4px 4px;
        border-collapse: separate;
        margin-bottom: 0;
    }
    .table th {
        font-weight: normal;
    }
    .table th, .table td {
        border: 0;
    }
    .table thead th {
        vertical-align: top;
        border-bottom: 0;
    }
    .table .cells {
        padding: 0;
    }
    .style1 {
        background-color: #e7e5e6;
        vertical-align: middle;
    }
    .cells.style3,
    .style2 {
        background-color: #f4f4f4;
        vertical-align: middle;
    }
    .style3 {
        background-color: #d0e5f8;
        vertical-align: middle;
    }
    .style4 {
        background-color: #d2ebf2;
        vertical-align: middle;
    }
</style>
