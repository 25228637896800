<template>
    <b-row class="calculLine mb-3">
        <b-col>
            <component
                v-if="type"
                :is="type"
                :name="name"
                :currency="currency"
                :model-h="modelH"
                :index="index"
                :total="total"
            />
            <div v-else>
                {{ name }}
            </div>
        </b-col>
    </b-row>
</template>

<script>
    import LineAvance from "./LineAvance";
    import LineExploitation from "./LineExploitation";
    import LineOption from "./LineOption";
    import LineRedevance from "./LineRedevance";

    export default {
        name: "DroitCell",
        props: ['index', 'modelH', 'currency', 'type', 'name', 'total'],
        components: {LineAvance, LineExploitation, LineOption, LineRedevance},
    }
</script>

<style scoped>
    .col {
        padding: 0
    }
</style>
