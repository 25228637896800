<template>
    <div>
        <b-card
            class="col-sm-12 col-md-6 col-lg-4"
        >
            <b-card-text>
                <b-row>
                    <b-col>Code </b-col>
                    <b-col>
                        <b-form-input size="sm" v-model="element.code" v-if="element" maxlength="2"></b-form-input>
                        <b-form-input size="sm" v-model="newLanguage.code" v-else  maxlength="2"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>Title </b-col>
                    <b-col>
                        <b-form-input size="sm" v-model="element.title" v-if="element" maxlength="30"></b-form-input>
                        <b-form-input size="sm" v-model="newLanguage.title" v-else  maxlength="30"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>Css extension </b-col>
                    <b-col>
                        <b-form-input size="sm" v-model="element.cssExtension" v-if="element"></b-form-input>
                        <b-form-input size="sm" v-model="newLanguage.cssExtension" v-else></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col cols="8">is bold ?</b-col>
                            <b-col cols="4">
                                <b-icon v-if="element"
                                    :icon="element.isBold ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                    :variant="element.isBold ? 'success' : 'danger'"
                                    v-model="element.isBold"
                                    @click="toggle( 'isBold', element)"
                                />
                                <b-icon v-else
                                        :icon="newLanguage.isBold ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                        :variant="newLanguage.isBold ? 'success' : 'danger'"
                                        v-model="newLanguage.isBold"
                                        @click="toggle( 'isBold', newLanguage)"
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="8">is online ?</b-col>
                            <b-col cols="4">
                                <b-icon v-if="element"
                                    :icon="element.state ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                    :variant="element.state ? 'success' : 'danger'"
                                    v-model="element.state"
                                    @click="toggle( 'state', element)"
                                />
                                <b-icon v-else
                                        :icon="newLanguage.state ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                        :variant="newLanguage.state ? 'success' : 'danger'"
                                        v-model="newLanguage.state"
                                        @click="toggle( 'state', newLanguage)"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col class="text-right mt-3">
                        <b-button size="sm" variant="primary" @click="element ? save(element) : create()">save</b-button>
                        <b-button size="sm" variant="danger" @click="del(element)" v-if="element">delete</b-button>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "Language-Admin",
        props: ["element", "priority", "getElements", "makeToast", "titleNotification"],
        data() {
            return {
                newLanguage: {
                    code: null,
                    cssExtension: null,
                    isBold: false,
                    state: false,
                },
                msg: null,
                parent: null,
            }
        },
        methods: {
            toggle(element, language) {
                language[element] = !language[element]
            },
            save(language) {
                const url = this.$store.state.apiUrl + language["@id"]
                axios.patch(
                    url,
                    language,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.patchHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.makeToast(language.code + ' has been modified', 'info', this.titleNotification)
                    }
                )
            },
            create() {
                if(this.newLanguage.code != null) {
                    const url = this.$store.state.apiUrl + '/api/languages'
                    this.newLanguage.priority = this.priority
                    axios.post(
                        url,
                        this.newLanguage,
                        {
                            'headers': {
                                'Content-Type': this.$store.state.postHeader,
                                'Authorization': 'Bearer ' + this.$store.state.accessToken
                            }
                        }
                    ).then(
                        response => {
                            this.getElements()
                            this.makeToast(this.newLanguage.code + ' has been created', 'success', this.titleNotification)
                            this.newLanguage.code = null
                            this.newLanguage.cssExtension = null
                            this.newLanguage.isBold = false
                            this.newLanguage.state = false
                        }
                    )
                }
            },
            del(language) {
                this.$bvModal.msgBoxConfirm('Are you sure?')
                    .then(value => {
                        if (value) {
                            const url = this.$store.state.apiUrl + language["@id"]
                            axios.delete(
                                url,
                                {
                                    'headers': {
                                        'Authorization': 'Bearer ' + this.$store.state.accessToken
                                    }
                                }
                            ).then(
                                response => {
                                    this.getElements()
                                    this.makeToast(language.code + ' has been deleted', 'warning', this.titleNotification)
                                }
                            )
                        }
                    })
                    .catch(err => {
                        // An error occurred
                    })
            }
        }
    }
</script>

<style scoped>
    .b-icon {
        cursor: pointer;
    }
</style>
