<template>
    <b-table-simple>
        <b-tr class="firstLine">
            <b-td><span v-html="title1"/></b-td>
        </b-tr>
        <b-tr>
            <b-td class="test"/>
        </b-tr>
        <b-tr class="secondLine">
            <b-td><span v-html="title2"/></b-td>
        </b-tr>
    </b-table-simple>
</template>

<script>
    export default {
        name: "DisciplineCells",
        props: ["title1", "title2"],
    }
</script>

<style scoped>
    .test {
        background-color: #FFFFFF;
        height: 4px !important;
        padding: 0;
    }
    .table {
        border-spacing: 0;
        border-collapse: unset;

    }
    .table tr {
        padding: 0;
    }
    .table td {
        border: 0;
    }
    .firstLine td{
        height: 60px;
    }
    .secondLine td{
        height: 40px;
    }


    .style3 .secondLine{
        background-color: #d0e5f8;
        vertical-align: middle;
    }
    .style3 .firstLine {
        background-color: #f4f4f4;
        vertical-align: middle;
    }
</style>
