<template>
    <b-row class="tarifs">
        <b-table-simple class=" mb-4 mt-4" responsive="sm" v-if="prices && options">
            <b-thead>
            <b-tr>
                <b-th class="colTarif text-uppercase">{{ $t('tarifs.options') }}</b-th>
                <b-th v-for="price in prices" v-if="price.state" :key="price.id" class="colTarif">
                    <b-row>
                        <b-col class="name">{{ $t('tarifs.tarif'+price.id) }}</b-col>
                    </b-row>
                    <b-row>
                        <b-col class="tarif">{{ price.amount }} <span class="devise">€</span></b-col>
                    </b-row>
                    <b-row class="legende">
                        <b-col cols="12" v-html="$t('tarifs.abonnementannuel')"></b-col>
                        <b-col cols="12" v-html="$t('tarifs.horstva')" v-if="price.amount > 0"></b-col>
                    </b-row>
                </b-th>
            </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="option in options" :key="option.id" v-if="option.state">
                    <b-td :class="option.color">
                        <b-row>
                            <b-col sm="2" class="ico0"><i :class="'icon-ICO'+option.icon"/></b-col>
                            <b-col sm="9" class="text-left" v-html="$t('tarifs.'+option.name)"></b-col>
                        </b-row>
                    </b-td>
                    <b-td
                        v-for="price in prices"
                        v-if="price.state"
                        :key="price.id"
                        :class="option.color"
                        class="align-middle"
                    >
                        <b-row class="text-center" v-if="priceOptions && priceOptions[price.id+'_'+option.id]">
                            <b-col
                                v-if="option.type === 'name'"
                                :class="(option.isBold) ? 'font-weight-bold' : ''"
                            >
                                <b-row>
                                    <b-col
                                        v-html="$t('tarifs.' + priceOptions[price.id+'_'+option.id]['value'])"
                                        :class="('pro' === price.name) ? 'text-danger': ''"
                                    />
                                </b-row>
                                <b-row v-if="price.name === 'solo'">
                                    <b-col>
                                        <b-form-select v-model="selected" size="sm" v-if="domains" @change="error=null">
                                            <b-form-select-option :value="null">{{ $t('website.auchoix') }}</b-form-select-option>
                                            <b-form-select-option v-for="domain in domains" v-if="domain.menu" :value="domain" :key="domain.id">
                                                {{ $t('menu.'+domain.title) }}
                                            </b-form-select-option>
                                        </b-form-select>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col v-else-if="option.type === 'state'">
                                <span v-if="priceOptions[price.id+'_'+option.id]['value']">
                                    <i class="icon-ICOcheck"></i>
                                </span>
                                <span v-else>-</span>
                            </b-col>
                            <b-col v-else :class="(option.isBold) ? 'font-weight-bold' : ''">
                                <span
                                    :class="('education' === price.name) ? 'text-danger font-weight-bold': ''"
                                >
                                    {{ priceOptions[price.id+'_'+option.id]['value'] }}
                                </span>
                                <span v-if="option.type === 'amount'"> €</span>
                            </b-col>
                        </b-row>
                        <b-row v-else>
                            <b-col class="text-center font-weight-bold">-</b-col>
                        </b-row>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td></b-td>
                    <b-td v-for="price in prices" v-if="price.state" :key="price.id" class="text-center">
                        <b-button
                            v-html="(account && account.lastOrder && validationDate < 0) ? $t('tarifs.renew') : $t('tarifs.sabonner')"
                            variant="success"
                            @click="subscribe(price)"
                            v-if="!account || (!account.lastOrder && !account.enterprise.lastOrder) || (account.lastOrder && validationDate < 0) || (account.enterprise && account.enterprise.lastOrder && validationDate < 0)"
                        />
                        <div v-else>
                            <div
                                v-if="account && account.lastOrder && account.lastOrder.price.id === price.id"
                                v-html="$t('tarifs.monabonnement')"
                                class="text-uppercase text-danger mt-2 mb-2"
                            />
                            <b-button
                                v-html="$t('tarifs.upgrade')"
                                variant="success"
                                @click="subscribe(price)"
                                v-if="account && account.lastOrder && account.lastOrder.price.id < price.id"
                            />
                        </div>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-col cols="12" class="text-center" v-else>
            <Loading/>
        </b-col>

        <b-col cols="12" class="text-center" v-if="error">
            <b-alert show variant="danger" v-html="$t('tarifs.'+error)"/>
            <hr>
        </b-col>

    </b-row>
</template>

<script>
    import domainsJson from '../../../files/domains.json'
    import optionsJson from '../../../files/options.json'
    import pricesJson from '../../../files/prices.json'
    import priceOptionsJson from '../../../files/price-options.json'
    import Loading from "../../components/front/Loading";

    export default {
        name: "Prices",
        components: {Loading},
        data() {
            return {
                prices: pricesJson,
                options: optionsJson,
                priceOptions: priceOptionsJson,
                selected: null,
                domains: domainsJson,
                account: null,
                error: null,
                errorCode: null,
            }
        },
        computed: {
            user() {
                return this.$store.state.currentUser
            },
            validationDate() {
                if(this.account && this.account.lastOrder) {
                    return this.$moment(this.account.lastOrder.validationDate).diff(this.$moment())
                }
                if(this.account && this.account.enterprise.lastOrder) {
                    return this.$moment(this.account.enterprise.lastOrder.validationDate).diff(this.$moment())
                }
                return
            }
        },
        watch: {
            user(newUser, oldUser) {
                this.getAccount()
            },
        },
        created() {
            if(this.$store.state.currentUser && !this.$store.state.accessToken) {
                this.$router.push({name: 'paiement', params: {lng: this.$store.state.actLng}})
                return false
            }
            this.getAccount()
        },
        methods: {
            getAccount() {
                if(this.$store.state.currentUser) {
                    this.$getAccount(this.$store.state.apiUrl, this.$store.state.accessToken, this.$store.state.currentUser).then(
                        data => {
                            this.account = data
                        }
                    )
                } else {
                    this.account = null
                }
            },
            subscribe(price) {
                if(price.name != "solo" || this.selected !== null) {
                    this.$store.commit('setElement', {'key': 'domainPrice', 'value': this.selected})
                    this.$store.commit('setCurrentPrice', price)
                    if(this.account && this.account.lastOrder) {
                        this.$router.push({name: 'upgrade', params: {lng: this.$store.state.actLng}})
                    } else {
                        this.$router.push({name: 'register', params: {lng: this.$store.state.actLng}})
                    }
                } else {
                    this.error = 'alertediscipline'
                }
            },
        }
    }
</script>

<style scoped>
    .colTarif {
        width: 16%;
    }
    .colTarif:first-child {
        width: 20%;
    }
    table .name {
        color: #009e99;
        font-weight: bold;
        text-transform: uppercase;
    }
    table .legende {
        font-weight: normal;
        padding-top: 10px;
        font-size: 11px;
    }

    table .tarif .devise {
        font-size: 30px;
        position: absolute;
    }
    table.tarifs thead tr th {
        border-top: 1px solid #ccc;
        padding: 0.5rem 0.625rem 0.625rem;
    }
    table.tarifs thead tr th:first-child {
        color: #000;
        font-weight: normal;
    }
    table thead, table tfoot {
        background: whitesmoke;
        font-weight: bold;
    }
    table.tarifs .ico0 {
        padding-top: 5px;
    }
    .btn-success {
        background-color: #2bc815 !important;
        font-size: 13px !important;
        font-family: "ff-meta-web-pro",Verdana, Arial, Helvetica, sans-serif;
        text-transform: uppercase !important;
        /* font-weight: bold !important; */
        padding-top: 4px !important;
        bottom: 5px;
        /* position: absolute; */
        letter-spacing: 1px;
        margin: 5px 0;
        min-width: 50px !important;
        height: 28px;
    }
</style>
