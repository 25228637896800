<template>
    <div>
        <b-container class="mt-4 mb-4">
            <b-row>
                <b-col
                    sm="12"
                    v-for="i in 3"
                    :key="i"
                    class="blochome"
                >
                    <div class="titre" v-html="$t('home.titre'+i)"></div>
                    <div class="texte" v-html="$t('home.texte'+i)"></div>
                </b-col>
            </b-row>
        </b-container>
        <div class="blocGreenBottom">
            <b-container>
                <prices/>
            </b-container>
        </div>
    </div>
</template>

<script>
    import Prices from "./Prices";

    export default {
        name: "Home",
        components: {Prices},
        created() {
            this.clearStore()
        },
        methods: {
            clearStore() {
                this.$store.commit("setAction", null)
                this.$store.commit("setDomains", false)
            }
        }
    }
</script>

<style scoped>
    .titre {
        margin-bottom: 10px;
        font-size: 28px;
        color: #009e99;
        line-height: 30px;
    }
    .texte {
      font-size: 17px;
      line-height: 22px;
    }
    .blochome {
        padding-top: 20px;
        padding-bottom: 20px;
        max-width: 70%;
        margin-right: auto;
        margin-left: auto;
    }
    .blocGreenBottom {
        background-color: #eaf5f7;
    }
</style>
