<template>
    <div>
        <b-form id="payment-form">
        <b-container class="mb-5">
            <b-row class="mt-5">
                <b-col class="titre mb-2">
                    <span v-html="$t('website.sabonnera')"/>
                    <span v-if="exemple">
                        {{ $t('domaines.' + exemple.topic.domain.title) }} /
                        {{ $t('domaines.' + exemple.topic.title) }} /
                        <span v-html="$t('examples.' + exemple.title)"/>
                    </span>
                </b-col>
            </b-row>
            <hr/>
            <b-row v-if="displayUser">
                <b-col cols="6">
                    <b-row>
                        <b-col>
                          <div>
                            <span v-html="$t('tarifs.soldearegler')"/> <span v-html="$t('tarifs.horstva')"/> =
                          </div>
                          <div class="tarif">{{ amount }} <span class="devise">€</span></div>
                          <div class="font-weight-bold mt-5"><span v-html="$t('website.tva')"/> = {{ tva }} €</div>
                          <div class="font-weight-bold mb-3">
                            <span v-html="$t('tarifs.totalttcpaiement')"/> = {{ total }} <sup>€</sup>
                          </div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="6" v-if="amount && amount > 0">
                    <label class="titre" v-if="card && !loading">Paiement</label>
                    <div id="card-element"/>
                    <b-button
                        class="mt-2"
                        size="sm"
                        variant="primary"
                        id="card-button"
                        @click="submitPayment"
                        v-if="card && !loading"
                    >
                        {{ $t('website.payer') }}
                    </b-button>
                    <Loading v-if="loading"/>
                    <div id="payment-result"/>
                    <b-alert variant="danger" show v-if="errorMessage" class="mt-2">{{ errorMessage }}</b-alert>
                </b-col>
            </b-row>
            <hr/>
        </b-container>
        </b-form>
    </div>
</template>

<script>
    import axios from "axios";
    import pricesJson from "../../../../files/prices.json";
    import priceOptionsJson from "../../../../files/price-options.json";
    import Loading from "../../../components/front/Loading";

    export default {
        name: "ExamplePayment",
        components: {Loading},
        data() {
            return {
                account: null,
                price: null,
                stripe: null,
                card: null,
                cs: null,
                order: null,
                loading: false,
                errorMessage: null,
                launchPayment: false,
                displayUser: null,
                prices: pricesJson,
                priceOptions: priceOptionsJson,
            }
        },
        computed: {
            amount() {
                let value = 0
                this.prices.forEach((price) => {
                    if(price.name === this.account.lastOrder.price.name) {
                        this.price = price
                        value = this.$store.state.priceoption.value
                    }
                })
                return value
            },
            tva() {
              return (this.total - this.amount).toFixed(2)
            },
            total() {
                return (this.amount * this.$store.state.tva).toFixed(2)
            },
            stripeAPIToken() {
                return this.$store.state.stripeKey
            },
            exemple() {
                return this.$store.state.exemple
            },
        },
        mounted() {
            this.displayUser = this.user
            if(this.$store.state.currentUser) {
                this.$getAccount(this.$store.state.apiUrl, this.$store.state.accessToken, this.$store.state.currentUser).then(
                    data => {
                        this.account = data
                        this.displayUser = data

                        this.includeStripe('js.stripe.com/v3/', function(){
                            this.configureStripe();
                        }.bind(this) );
                    }
                )
            }
        },
        methods: {
            includeStripe( URL, callback ){
                let documentTag = document, tag = 'script',
                    object = documentTag.createElement(tag),
                    scriptTag = documentTag.getElementsByTagName(tag)[0];
                object.src = '//' + URL;
                if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                scriptTag.parentNode.insertBefore(object, scriptTag);
            },
            configureStripe(){
                this.stripe = Stripe( this.stripeAPIToken );

                let params = {}
                params.priceOptionId = this.$store.state.priceoption.id
                params.accessToken = this.$store.state.accessToken
                axios.post(
                    '/payment-intent',
                    JSON.stringify(params),
                    {
                        'headers': {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(
                    response => {
                        const elements = this.stripe.elements();
                        this.card = elements.create('card');

                        this.card.mount('#card-element');

                        this.cs = response.data.clientSecret
                    }
                )
            },
            submitPayment() {
                if(!this.launchPayment) {
                    this.launchPayment = true
                    this.stripe.confirmCardPayment(
                        this.cs, {
                            payment_method: {
                                card: this.card
                            }
                        }
                    ).then(
                        response => {
                            if(response.error) {
                                this.errorMessage = response.error.message
                            } else {
                                if(response.paymentIntent.status === "succeeded") {
                                    this.setOrder(response.paymentIntent.id)
                                }
                            }
                        }
                    )
                }
            },
            setOrder(token) {
                let ref = 'C' + this.$moment().format('YYYYMMDD')
                this.addOrder(token, ref)
            },
            addOrder(token, ref) {
                let params = {}
                params.client = this.displayUser['@id']
                params.stripeToken = token
                params.reference = ref
                params.amount = this.total.toString()
                params.vatRate = this.$store.state.tva.toString()
                if(this.$store.state.exemple) {
                    params.example = this.$store.state.exemple['@id']
                } else {
                    params.price = this.price['@id']
                }
                params.state = true
                params.paymentDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
                params.validationDate = this.$moment().add(12, 'months').format('YYYY-MM-DD HH:mm:ss')

                const url = this.$store.state.apiUrl + '/api/orders'
                axios.post(
                    url,
                    params,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.postHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.order = response.data
                        this.$router.push({name: 'confirmation', params: {lng: this.$store.state.actLng}})
                    }
                )
            },
        }
    }
</script>

<style scoped>
    #custom-button {
        height: 30px;
        outline: 1px solid grey;
        background-color: green;
        padding: 5px;
        color: white;
    }

    #card-error {
        color: red;
    }
</style>
