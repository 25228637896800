<template>
    <div class="menu">
        <div class="container">
            <div class="row">
                <div class="col-sm-11 col-md-12 text-center" v-if="domains">
                    <span>
                        <router-link :to="{ name: 'discipline', params: {lng: lng}}" active-class="active">
                            <b-icon-list-ul scale="2.5" class="mr-1 pt-1"/>
                        </router-link>
                        <span> | </span>
                    </span>
                    <span v-for="(domain, index) in domains">
                        <router-link :to="{ name: 'domaine', params: {slug: domain.title, id: domain.id, lng: lng}}" active-class="active">
                            {{ $t('menu.'+domain.title) }}
                        </router-link>
                        <span v-if="index < domains.length-1"> | </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"

    export default {
        name: "Menu",
        data() {
            return {
                domains: null,
                currentDomain: null
            }
        },
        computed: {
            lng() {
                return this.$store.state.actLng
            },
        },
        mounted() {
            this.getMenu()
        },
        methods: {
            getMenu() {
                let url = this.$store.state.apiUrl + '/api/front/domains?menu=1'
                //url = '../files/domains.json'
                axios.get(
                    url
                ).then(
                    response => {
                        this.domains = response.data["hydra:member"]
                        //this.domains = response.data
                    }
                )
            },
            getDomain(domain) {
                this.currentDomain = domain
            }
        }
    }
</script>

<style scoped>
    .menu {
        min-height: 33px;
        color: #FFFFFF;
        font-size: 15px;
        font-family: "ff-meta-web-pro", "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
        letter-spacing: normal;
        padding-top: 18px;
        padding-bottom: 15px;
        text-align: center;
        background: #76cdcf;
        background: -moz-linear-gradient(top, #76cdcf 0%, #32a7a4 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#76cdcf), color-stop(100%,#32a7a4));
        background: -webkit-linear-gradient(top, #76cdcf 0%,#32a7a4 100%);
        background: -o-linear-gradient(top, #76cdcf 0%,#32a7a4 100%);
        background: -ms-linear-gradient(top, #76cdcf 0%,#32a7a4 100%);
        background: linear-gradient(to bottom, #76cdcf 0%,#32a7a4 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#76cdcf', endColorstr='#32a7a4',GradientType=0 );
    }
    a {
        color: #FFFFFF;
    }
    a.active,
    a:hover {
        color: #000000;
    }
</style>
