<template xmlns="http://www.w3.org/1999/html">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <b-container>
                <div class="titre mb-2 mt-5" v-html="$t('home.mdp-menu')"></div>
                <hr>
                <b-row>
                    <b-col cols="12">
                        <b-form inline>
                            <b-form-input size="sm" v-model="form.email"></b-form-input>
                        </b-form>
                        <div v-if="errorCode">
                            <b-alert show variant="danger" size="sm" v-html="$t('website.'+errorCode)"/>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
            <bottom-actions
                :button-text="$t('website.page_souscrire_bt_verifier')"
                :set-action="setAction"
            />
        </b-form>
    </validation-observer>
</template>

<script>
    import axios from "axios";
    import BottomActions from "../../../components/footer/BottomActions";
    import FormSubscription from "../../../components/form/Subscription";
    import PriceOption from "../../../components/front/PriceOption";
    import querystring from "querystring";

    export default {
        name: "Lost",
        data() {
            return {
                errorCode: null,
                form: {
                    email: null
                }
            }
        },
        components: {BottomActions, FormSubscription, PriceOption},
        computed: {
            /*price() {
                return this.$store.state.currentPrice
            },
            amount() {
                if(this.discountAmount) {
                    return this.discountAmount
                }
                let valAmount = this.price.amount
                if(this.account && this.account.lastOrder && this.validationDate >= 0) {
                    valAmount -= this.account.lastOrder.amount
                }
                return valAmount
            },
            discountAmount: {
                get() {
                    return this.discount
                },
                set(val) {
                    this.discount = val
                }
            },
            total() {
                return (this.amount * this.$store.state.tva).toFixed(2)
            },
            tva() {
                return (this.total - this.amount).toFixed(2)
            },
            dateend() {
                return this.$moment().add(this.$store.state.subscriptionDuration, 'M').format('DD/MM/YYYY')
            },
            domainSelected() {
                return this.$store.state.domainPrice
            },
            user() {
                return this.$store.state.currentUser
            },
            validationDate() {
                if(this.account && this.account.lastOrder) {
                    return this.$moment(this.account.lastOrder.validationDate).diff(this.$moment())
                }
                return
            },*/
        },
        methods: {
            setAction(name) {
                this.buttonName = name
            },
            /*getSubscriptionType() {
                let name = (this.$route.name === 'register') ? 'creation' : 'renew'
                const url = this.$store.state.apiUrl + '/api/front/subscription_types?name=' + name
                axios.get(
                    url
                ).then(
                    response => {
                        this.$store.commit('setElement', {key: 'subscriptionType', value: response.data['hydra:member'][0]})
                        this.init()
                    }
                )
            },
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
            onSubmit() {
                if(!this.user) {
                    this.checkSubscription()
                } else {
                    this.setSubscription()
                }
            },
            checkSubscription() {
                const refs = this.$refs.observer.refs;
                const url = this.$store.state.apiUrl + '/api/front/check_email?email=' + refs.email.value
                axios.get(
                    url
                ).then(
                    response => {
                        if(!response.data || response.data["hydra:totalItems"] !== 0) {
                            this.error = 'emailexistant'
                        } else {
                            this.setSubscription()
                        }
                    }
                )
            },
            setSubscription() {
                if(!this.user) {
                    const refs = this.$refs.observer.refs;
                    let params = {}
                    Object.keys(refs).forEach(function(key){
                        let val = refs[key].value
                        if(key === "country") {
                            val = refs[key].value
                        }
                        params[key] = val
                    });

                    this.addKeycloakUser(params)
                } else {
                    /!*if(this.price.name === 'free') {
                        this.$router.push({name: 'confirmation', params: {lng: this.$store.state.actLng}})
                    } else {*!/
                        this.$router.push({name: 'paiement', params: {lng: this.$store.state.actLng}})
                   // }

                    //this.updateUser()
                }
            },
            addKeycloakUser(params) {
                const url = '/user/create-keycloak'
                axios.post(
                    url,
                    params
                ).then(
                    response => {
                        this.getKeycloakUser(params)
                    }
                )
            },
            getKeycloakUser(user) {
                const url = '/user/keycloak'
                let params = {}
                params.email = user.email
                axios.post(
                    url,
                    params
                ).then(
                    response => {
                        user.keycloakId = response.data
                        this.addUser(user)
                    }
                )
            },
            addUser(params) {
                const url = this.$store.state.apiUrl + '/api/front/subscription'
                axios.post(
                    url,
                    params,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.postHeader,
                        }
                    }
                ).then(
                    response => {
                        this.$accessLogin(params.email, params.password)
                            .then(token => {
                                this.$store.commit("setUser", response.data)
                                this.$setTokens(token)
                                //if(this.price.name === 'free') {
                                //    this.$router.push({name: 'confirmation', params: {lng: this.$store.state.actLng}})
                                //} else {
                                    this.$router.push({name: 'paiement', params: {lng: this.$store.state.actLng}})
                                //}
                            })
                    }
                )
            },
            updateUser(params) {
                const url = this.$store.state.apiUrl + '/api/clients/' + this.user.id
                axios.patch(
                    url,
                    params,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.patchHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken,
                        }
                    }
                ).then(
                    response => {
                        this.$store.commit("setUser", response.data)
                        if(this.price.name === 'free') {
                            this.$router.push({name: 'confirmation', params: {lng: this.$store.state.actLng}})
                        } else {
                            this.$router.push({name: 'paiement', params: {lng: this.$store.state.actLng}})
                        }
                    }
                )
            },
            checkCode() {
                this.errorCode = null
                let url = this.$store.state.apiUrl + '/api/discount_codes?state=1'
                url += '&code=' + this.form.code
                url += '&endDate[after]=' + this.$moment().format('YYYY-MM-DD')
                url += '&subscriptionType=' + this.price.id
                axios.get(
                    url
                ).then(
                    response => {
                        this.discount = null
                        const code = response.data["hydra:member"][0]
                        if(code) {
                            this.discount = code.value
                            this.$store.commit('setElement', {key: 'discountCode', value: this.form.code})
                        } else {
                            this.errorCode = 'erreur_code'
                        }
                    }
                )
            },
            init() {
                Object.keys(this.form).forEach((key) => {
                    this.form[key] = null
                })
                this.form.cgu = false
                this.form.country = {}
                this.form.country["@id"] = null

                if(this.user) {
                    Object.keys(this.form).forEach((key) => {
                        this.form[key] = this.user[key]
                    })
                    if(this.user.country) {
                        this.form.country['@id'] = this.user.country['@id']
                    }
                }
            },*/
        }
    }
</script>

<style scoped>
    .stitre {
        font-weight: bold;
        color: #000000;
        font-size: 15px !important;
        line-height: 17px;
    }
</style>
