<template>
    <div class="droittop">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-container>
                <b-row class="justify-content-md-center" >
                    <b-col sm="12" md="6">
                        <b-row class="justify-content-md-center" sm="12" md="6">
                            <b-col sm="12" md="5" class="mt-1">
                                <b-row v-html="$t('website.04_p01_titre')+'&nbsp;:'" class="justify-content-end"/>
                            </b-col>
                            <b-col sm="12" md="7">
                                <b-form inline class="row" @submit.stop.prevent="handleSubmit(onSubmit)">
                                    <b-form-input
                                        size="sm"
                                        :placeholder="$t('home.showhono')"
                                    />
                                    <b-button
                                        variant="primary"
                                        size="sm"
                                        class="sublog lien ml-1"
                                        type="submit"
                                        v-html="$t('website.00a_calculda_calculer')"
                                        v-if="user"
                                    />
                                    <b-button
                                        v-else
                                        variant="primary"
                                        size="sm"
                                        class="sublog lien ml-1"
                                        v-html="$t('website.00a_calculda_calculer')"
                                        v-b-tooltip.hover.bottom="{ customClass: 'my-tooltip' }" :title="$t('website.abominrequis') + 'FREE'"
                                    />
                                </b-form>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-container>
        </validation-observer>
    </div>
</template>

<script>
    export default {
        name: "Rights",
        computed: {
            user() {
                return this.$store.state.currentUser
            },
        },
        methods: {
            onSubmit() {
                //console.log('submit rights')
            },
        },
    }
</script>

<style scoped>
    .droittop {
        min-height: 47px;
        color: #FFFFFF;
        font-size: 13px;
        font-family: "ff-meta-web-pro", "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
        letter-spacing: normal;
        padding-top: 10px;
        padding-bottom: 0px;
        text-align: center;
        background: #073036;
        background: -moz-linear-gradient(top, #073036 0%, #0b383e 50%, #0a393f 100%);
        background: -webkit-linear-gradient(top, #073036 0%,#0b383e 50%,#0a393f 100%);
        background: linear-gradient(to bottom, #073036 0%,#0b383e 50%,#0a393f 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#073036', endColorstr='#0a393f',GradientType=0 );
    }
    .titre {
        padding-top: 5px;
    }
    .droittop input[type=text] {
        background-color: #02202a;
        color: #fff;
        border: 0;
        margin: 2px 0px 0px 2px;
        height: 23px;
        padding: 0px 5px !important;
        width: 75%;
    }
</style>
