<template>
    <div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" v-if="account">
                <b-container>
                    <b-row class="mt-5 mb-1">
                        <b-col sm="12" md="6">
                            <div class="titre mb-4" v-html="$t('website.00b_profil')"></div>
                        </b-col>
                    </b-row>
                    <b-row class="mb-5">
                        <b-col sm="12" md="5">
                            <div v-html="$t('website.intropreferences')"/>
                            <div v-if="countries" class="mt-2">
                                <b-form-select v-model="account.countryProfil['@id']" size="sm">
                                    <b-form-select-option :value="null">{{ $t('website.choisirpays') }}</b-form-select-option>
                                    <b-form-select-option
                                        v-for="country in countries"
                                        :key="country.id"
                                        :value="country['@id']"
                                    >
                                        {{ $t('countries.' + country.code) }}
                                    </b-form-select-option>
                                </b-form-select>
                            </div>
                            <div v-if="currencies" class="mt-2">
                                <b-form-select v-model="account.currency['@id']" size="sm">
                                    <b-form-select-option :value="null">{{ $t('website.choisirmonnaie') }}</b-form-select-option>
                                    <b-form-select-option
                                        v-for="currency in currencies"
                                        :key="currency.id"
                                        :value="currency['@id']"
                                    >
                                        {{ $t('devises.' + (currency.code.toLowerCase())) }}
                                    </b-form-select-option>
                                </b-form-select>
                            </div>
                            <div v-html="$t('website.personnalise')" class="mt-4"/>
                            <b-row class="mt-3">
                                <b-col cols="7">
                                    <b-input-group size="sm" :prepend="$t('website.tauxpersonnalise')">
                                        <b-form-input v-model="account.hourRate"/>
                                    </b-input-group>
                                </b-col>
                            </b-row>
                            <b-row class="mt-3">
                                <b-col cols="11">
                                    <b-input-group size="sm" :prepend="$t('website.tvapersonnalise')" append="%">
                                        <b-form-input v-model="account.vatRate"/>
                                    </b-input-group>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="12" md="2"/>
                        <b-col sm="12" md="5">
                            <div v-html="$t('website.enquete')" class="font-weight-bold"/>
                            <div v-html="$t('website.enquete_legende')"/>
                            <div>
                                <b-form-group>
                                    <b-form-radio-group
                                        v-model="account.ckFigure"
                                    >
                                        <b-form-radio value="0"><div class="mt-1">{{ $t('website.non') }}</div></b-form-radio>
                                        <b-form-radio value="1"><div class="mt-1">{{ $t('website.oui') }}</div></b-form-radio>
                                    </b-form-radio-group>
                                </b-form-group>

                            </div>
                            <div v-html="$t('website.00b_profildesigner')" class="font-weight-bold mt-4"/>
                            <div class="mt-2">
                                <b-form-select v-model="account.profil['@id']" size="sm">
                                    <b-form-select-option :value="null">{{ $t('website.choisir') }}</b-form-select-option>
                                    <b-form-select-option
                                        v-for="profil in profils"
                                        :key="profil.id"
                                        :value="profil['@id']"
                                    >
                                        {{ $t('website.' + profil.name) }}
                                    </b-form-select-option>
                                </b-form-select>
                            </div>
                            <div v-html="$t('website.00b_activite')" class="font-weight-bold mt-2"/>
                            <div class="mt-2">
                                <b-form-select v-model="account.activity['@id']" size="sm">
                                    <b-form-select-option :value="null">{{ $t('website.choisir') }}</b-form-select-option>
                                    <b-form-select-option
                                        v-for="activity in activities"
                                        :key="activity.id"
                                        :value="activity['@id']"
                                    >
                                        {{ $t('website.' + activity.name) }}
                                    </b-form-select-option>
                                </b-form-select>
                            </div>
                            <div v-html="$t('website.00b_tauxmoy')" class="font-weight-bold mt-2"/>
                            <div class="mt-2">
    <!--                            {{ moyennes }}-->
                                <b-form-select v-model="domainSelected" size="sm">
                                    <b-form-select-option
                                        v-for="domain in domains"
                                        :key="domain.id"
                                        :value="domain"
                                        v-if="domain.menu"
                                    >
                                        {{ $t('domaines.' + domain.title) }}
                                    </b-form-select-option>
                                </b-form-select>
                            </div>

                            <b-row v-if="account.lastOrder && account.lastOrder.price && account.lastOrder.price.name !== 'free' && account.lastOrder.price.name !== 'solo'">
                                <b-col cols="8">
                                    <router-link
                                        :to="{ name: 'disciplines', params: {lng: lng}}"
                                    >
                                        <b-button v-html="$t('website.00b_taux_disciplines')" size="sm" variant="primary" class="mt-4"/>
                                    </router-link>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-container>
                <bottom-actions
                    :button-text="$t('website.go')"
                    :set-action="setAction"
                />
            </b-form>
            <b-container v-else>
                <b-row class="mt-5 mb-5">
                    <b-col class="text-center">
                        <Loading/>
                    </b-col>
                </b-row>
            </b-container>
        </validation-observer>
        <message :msg="msg" :type-message="typeMessage"/>
    </div>
</template>

<script>
    import activitesJson from '../../../../files/activitiestypes.json'
    import countriesJson from '../../../../files/countries.json'
    import currenciesJson from '../../../../files/currencies.json'
    import profilsJson from '../../../../files/profilstypes.json'
    import domainsJson from '../../../../files/domains.json'
    import BottomActions from "../../../components/footer/BottomActions";
    import axios from "axios";
    import Loading from "../../../components/front/Loading";
    import Message from "../../../components/form/Message";

    export default {
        name: "Profil",
        components: {Loading, BottomActions, Message},
        data() {
            return {
                msg: null,
                typeMessage: 'success',
                account: {},
                countries: countriesJson,
                currencies: currenciesJson,
                activities: activitesJson,
                profils: profilsJson,
                domains: domainsJson,
                domainSelected: domainsJson[0],
                form: {
                    countryProfil: {},
                    currency: {},
                    hourRate: null,
                    vatRate: null,
                    ckFigure: null,
                    profil: {},
                    activity: {},
                },
            }
        },
        computed: {
            lng() {
                return this.$store.state.actLng
            },
            moyennes() {
                let moy = []
                return moy
            },
        },
        created() {
            this.init()
            if(!this.$store.state.currentUser) {
                this.$router.push({name: 'home', params: {lng: this.$store.state.actLng}})
                return false
            }
            if(!this.$store.state.accessToken) {
                this.$router.push({name: 'paiement', params: {lng: this.$store.state.actLng}})
                return false
            }
            this.$getAccount(this.$store.state.apiUrl, this.$store.state.accessToken, this.$store.state.currentUser).then(
                data => {
                    this.account = data
                    if(!this.account.lastOrder && !this.account.enterprise.lastOrder) {
                        this.$router.push({name: 'home', params: {lng: this.$store.state.actLng}})
                    }
                    Object.keys(this.form).forEach((key) => {
                        this.form[key] = this.account[key]
                    })
                    this.init()
                }
            )
        },
        mounted() {
            this.$store.commit("setDomainStep", null)
        },
        methods: {
            setAction(name) {
                this.buttonName = name
            },
            onSubmit() {
                this.message = null
                Object.keys(this.form).forEach((key) => {
                    this.form[key] = this.account[key]
                })
                this.form.ckFigure = 1*this.account.ckFigure
                this.form.countryProfil = this.account.countryProfil['@id']
                this.form.currency = this.account.currency['@id']
                this.form.profil = this.account.profil['@id']
                this.form.activity = this.account.activity['@id']
                this.form.hourRate = 1*this.account.hourRate
                const url = this.$store.state.apiUrl + this.account["@id"]
                axios.patch(
                    url,
                    this.form,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.patchHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.msg = this.$t('website.majprofil')
                        this.typeMessage = 'success'
                    }
                ).catch(
                    error => {
                    }
                )
            },
            init() {
                if(!this.account.countryProfil) {
                    this.account.countryProfil = {}
                    this.account.countryProfil["@id"] = null
                }
                if(!this.account.currency) {
                    this.account.currency = {}
                    this.account.currency["@id"] = null
                }
                if(!this.account.profil) {
                    this.account.profil = {}
                    this.account.profil["@id"] = null
                }
                if(!this.account.activity) {
                    this.account.activity = {}
                    this.account.activity["@id"] = null
                }
            },
        },
    }
</script>

<style scoped>
    .input-group-text {
        border: 0;
        background-color: transparent;
    }
</style>
