<template>
    <div>
        <section class="pdf-content page" v-if="order">
            <b-row class="mb-2">
                <b-col>
                    <div class="titre" v-html="$t('website.facture_moneydesign')"/>
                </b-col>
            </b-row>

            <b-row align-h="end">
                <b-col cols="4" class="mt-5 mb-5">
                    <div>{{ account.firstname }} {{ account.lastname }}</div>
                    <div v-if="account.society">{{ account.society }}</div>
                    <div>{{ account.address }}</div>
                    <div>{{ account.zipcode }} {{ account.city }}</div>
                  <div v-if="account.country">{{ $t('countries.' + account.country.code) }}</div>
                    <div v-if="account.vatNumber">
                        <br />
                        <span v-html="$t('website.page_souscrire_numerotva')"/> :
                        <span v-html="account.vatNumber"/>
                    </div>
                </b-col>
            </b-row>

            <b-row class="mt-5 order-detail" align-h="end">
               <b-col cols="10" class="mt-5">
                   <div class="font-weight-bold mt-">
                       <span v-html="$t('website.facture_numero')" class="mt-5"/>
                       {{ order.reference }}
                   </div>
                   <div v-if="order.example">
                       <span v-html="$t('domaines.' + order.example.topic.domain.title)"/> /
                       <span v-html="$t('domaines.' + order.example.topic.title)"/> /
                       <span v-html="$t('examples.' + order.example.title)"/>
<!--                       <span v-html="$t('website.facture_exemple')"/> :-->
                   </div>
                   <div v-else>
                       <span v-html="$t('website.typeabonnement')"/>
                       <span v-html="tarif" v-if="tarif"/><span v-if="order.domain" v-html="' ('+ $t('domaines.' + order.domain.title) +')'"/> :
                       <span v-html="designation" v-if="designation"/>
                   </div>
                   <div>
                       <span v-html="$t('home.du')" class="text-capitalize"/>
                       <span>{{ order.creationDate |moment('DD/MM/YYYY') }}</span>
                       <span v-html="$t('home.au')"/>
                       <span>{{ order.validationDate |moment('DD/MM/YYYY') }}</span>
                   </div>
               </b-col>
            </b-row>

            <b-row class="mt-5 mb-5" align-h="end">
               <b-col cols="10">
                   <div v-if="!order.vatNumber">
                       <div>
                           <span v-html="$t('tarifs.horstva')"/> = {{ ht |formatNumber }} {{ devise }}
                       </div>
                       <div>
                           <span v-html="$t('website.tva')"/> = {{ tva |formatNumber }} {{ devise }}
                       </div>
                   </div>
                   <div class="font-weight-bold">
                       <span v-html="$t('tarifs.totalttcpaiement')"/> = {{ order.amount |formatNumber }} {{ devise }}
                   </div>
                   <div v-if="order.vatNumber" v-html="$t('tarifs.mention_tva_facture')"/>
               </b-col>
            </b-row>


<!--            <b-row class="footer">
               <b-col class="text-center titre" v-html="$t('website.facture_siret')"/>
            </b-row>-->

        </section>
    </div>
</template>

<script>
    export default {
        name: "OrderBill",
        props: ["account", "order"],
        computed: {
            tarif() {
                return this.$t('tarifs.tarif' + this.order.price.id)
            },
            designation() {
                if(this.order.reference && this.order.reference.substr(0,1) === 'R') {
                    return this.$t('website.facture_ck2_maj')
                } else {
                    return this.$t('website.facture_designation')
                }
            },
            ht() {
                return Math.round(100*(this.order.amount/this.order.vatRate))/100
            },
            tva() {
                return Math.round(100*(this.order.amount - this.ht))/100
            },
            devise() {
                return '€'
            }
        },
    }
</script>

<style scoped>
    .footer {
        margin-top: 400px;
    }
    .footer .titre {
        font-size: 12px;
    }
    .titre {
        font-size: 14px;
        line-height: 18px;
    }
    .pdf-content {
        width: 100%;
        background: #fff;

    }
    .order-detail {
        line-height: 22px;
    }
    pre {
        padding: 0;
        background-color: transparent;
    }
</style>
