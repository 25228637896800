<template>
    <b-container>
        <b-row>
            <b-col sm="12" md="11" class="mt-2">
                <b-form inline class="float-right" v-if="!user">
                    <b-form-input
                        v-model="username"
                        id="inline-form-input-name"
                        class="mb-2 mr-sm-1 mb-sm-0 intro chplog"
                        :placeholder="$t('website.identifiant')"
                        size="sm"
                    />

                    <b-form-input
                        v-model="password"
                        class="mb-2 mr-sm-1 mb-sm-0 intro chplog"
                        id="inline-form-input-password"
                        :placeholder="$t('website.password')"
                        type="password"
                        @keyup.enter="signin"
                    />

                    <b-button class="sublog" v-html="$t('website.entrer')" @click="signin"/>
                    <span v-if="!admin && !user" v-html="$t('home.mdp-menu')" class="ml-3" @click="lost"/>
                </b-form>
                <div v-else class="text-right">
                    <router-link :to="{ name: 'account', params: {lng: lng}}" v-html="$t('website.preferences')"/>
                    <span class="ml-1 mr-1">|</span>
                    <router-link :to="{ name: 'profil', params: {lng: lng}}" v-html="$t('website.00b_profildesigner')"/>
                    <span class="ml-1 mr-1">|</span>
                    <a href="#" v-html="$t('website.sedeconnecter')" @click="logout"/>
                </div>
            </b-col>
            <b-col sm="6" md="1">
                <div v-if="!admin" class="float-right">
                    <i class="icon-ICOworld tooltipstered" id="map-ico"></i>
                    <b-tooltip
                        target="map-ico"
                        placement="bottom"
                    >
                        <div v-for="language in languages"><a href="#" @click="setLanguage(language.code)">{{ language.title }}</a></div>
                    </b-tooltip>
                </div>
            </b-col>
        </b-row>
        <modal-lost/>
    </b-container>
</template>

<script>
    import axios from "axios";
    import {serverBus} from "../../../app";
    import ModalLost from "../../pages/front/subscription/ModalLost";

    export default {
        name: "Login",
        components: {ModalLost},
        data() {
            return {
                username: null,
                password: null,
                languages: null,
            }
        },
        computed: {
            lng() {
                return this.$store.state.actLng
            },
            admin() {
                return this.$store.state.admin
            },
            user() {
                return this.$store.state.currentUser
            }
        },
        mounted() {
            this.getLanguages()
        },
        methods: {
            signin() {
                this.$clearStore()
                this.$accessLogin(this.username, this.password)
                    .then(token => {this.$setTokens(token)})
                    .then(token => {
                        this.$getUserinfo().then(
                            userinfo => {
                                this.$store.commit('setUser', userinfo)
                                this.$checkUser(userinfo).then(
                                    user => {
                                        this.$checkSession(user).then(
                                            session => {
                                                if(session.length > 1) {
                                                    serverBus.$emit('logout', true)
                                                } else {
                                                    this.$router.push({name: 'discipline'})
                                                }
                                            }
                                        )
                                    }
                                )
                            }
                        )
                    }
                )
            },
            logout() {
                serverBus.$emit('logout', true)
            },
            getLanguages() {
                const url = this.$store.state.apiUrl + '/api/front/languages?pagination=false&state=true'
                axios.get(url).then(
                    response => {
                        this.languages = response.data["hydra:member"]
                    }
                )
            },
            setLanguage(code) {
                this.$i18n.locale = code
                this.$store.commit("setLng", code)
            },
            lost() {
                this.$bvModal.show('modalLost')
            }
        }
    }
</script>

<style scoped>
    .intro {
        color: #666666 !important;
        font-size: 14px !important;
        line-height: 13px !important;
        letter-spacing: normal !important;
    }
    .chplog {
        background-color: #009e99 !important;
        width: 80px !important;
        height: 27px !important;
        border: 0 !important;
        border-radius: 2px !important;
        color: #ccffff !important;
        padding: 0px 5px !important;
        margin-bottom: 0 !important;
        font-size: 12px;
        font-family: "ff-meta-web-pro", Verdana, Arial, Helvetica, sans-serif;
    }
    .chplog:-moz-placeholder {
        color: #ccffff;
        font-size: 14px;
        font-family: "ff-meta-web-pro", Verdana, Arial, Helvetica, sans-serif;
        text-align: center;
    }
    .chplog::-webkit-input-placeholder {
        color: #ccffff;
        font-size: 14px;
        font-family: "ff-meta-web-pro", Verdana, Arial, Helvetica, sans-serif;
        text-align: center;
    }
    a {
        color: #ccffff;
    }
    a.active,
    a:hover {
        color: #000000;
    }
    .bs-tooltip-bottom {
        top: -15px !important;
    }
</style>
