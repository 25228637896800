<template>
    <div>
        <b-card
            class="col-sm-12 col-md-6 col-lg-4"
        >
            <b-card-text>
                <b-row>
                    <b-col>Title </b-col>
                    <b-col>
                        <b-form-input size="sm" v-model="contentType.name" v-if="contentType" maxlength="30"></b-form-input>
                        <b-form-input size="sm" v-model="newContentType.name" v-else  maxlength="30"></b-form-input>
                    </b-col>
                    <b-col class="text-right mt-1">
                        <b-button size="sm" variant="primary" @click="contentType ? save(contentType) : create()">save</b-button>
                        <b-button size="sm" variant="danger" @click="del(contentType)" v-if="contentType">delete</b-button>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ContentType-Admin",
        props: ["contentType", "getContentTypes", "makeToast"],
        data() {
            return {
                newContentType: {
                    name: null,
                },
                msg: null,
                parent: null,
                titleNotification: 'Type Notification',
            }
        },
        methods: {
            save(contentType) {
                const url = this.$store.state.apiUrl + contentType["@id"]
                axios.patch(
                    url,
                    contentType,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.patchHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.makeToast(contentType.name + ' has been modified', 'info', this.titleNotification)
                    }
                )
            },
            create() {
                if(this.newContentType.name != null) {
                    const url = this.$store.state.apiUrl + '/api/content_types'
                    axios.post(
                        url,
                        this.newContentType,
                        {
                            'headers': {
                                'Content-Type': this.$store.state.postHeader,
                                'Authorization': 'Bearer ' + this.$store.state.accessToken
                            }
                        }
                    ).then(
                        response => {
                            this.getContentTypes()
                            this.makeToast(this.newContentType.name + ' has been created', 'success', this.titleNotification)
                            this.newContentType.name = null
                        }
                    )
                }
            },
            del(contentType) {
                this.$bvModal.msgBoxConfirm('Are you sure?')
                    .then(value => {
                        if (value) {
                            const url = this.$store.state.apiUrl + contentType["@id"]
                            axios.delete(
                                url,
                                {
                                    'headers': {
                                        'Authorization': 'Bearer ' + this.$store.state.accessToken
                                    }
                                }
                            ).then(
                                response => {
                                    this.getContentTypes()
                                    this.makeToast(contentType.name + ' has been deleted', 'warning', this.titleNotification)
                                }
                            )
                        }
                    })
                    .catch(err => {
                        // An error occurred
                    })
            }
        }
    }
</script>

<style scoped>
    .b-icon {
        cursor: pointer;
    }
</style>
