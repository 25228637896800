<template>
    <div>
        <b-form id="payment-form">
        <b-container>
            <b-row v-if="displayUser" class="mt-5">
                <b-col cols="8">
                    <div class="titre mb-2" v-html="$t('website.page_confirmation_titre')"></div>
                    <div class="font-weight-bold mb-2" v-html="$t('website.page_souscrire_jesouscris')" v-if="!account"/>
                    <div class="mb-3">
                        <div>{{ displayUser.firstname }} {{ displayUser.lastname }}</div>
                        <div v-if="displayUser.job">{{ displayUser.job }}</div>
                        <div v-if="displayUser.society">{{ displayUser.society }}</div>
                        <div v-if="displayUser.address">{{ displayUser.address }}</div>
                        <div v-if="displayUser.zipcode">{{ displayUser.zipcode }} {{ displayUser.city }}</div>
                        <div v-if="displayUser.country">{{ $t('countries.' + displayUser.country.code) }}</div>
                        <div v-if="displayUser.phone">{{ displayUser.phone }}</div>
                        <div v-if="displayUser.email">{{ displayUser.email }}</div>
                    </div>
                    <hr v-if="account && account.lastOrder">
                    <b-row v-if="account && account.lastOrder && account.lastOrder.amount && validationDate > 0">
                        <b-col>
                            <div v-html="$t('tarifs.encours') + ' :'"/>
                            <span class="priceName">{{ account.lastOrder.price.name }}</span>
                            <span class="legende" v-html="$t('tarifs.abonnementannuel') + ' ' + $t('home.jusquau') + ' ' + dateValidation"></span>
                            <div><span class="font-weight-bold">{{ account.lastOrder.amount |formatNumber }}</span> <span class="devise">€</span> <span v-html="$t('tarifs.horstva')"/> </div>
                        </b-col>
                    </b-row>
                    <hr v-if="account && account.lastOrder && validationDate > 0">
                    <b-row v-if="price && account && account.lastOrder && validationDate > 0">
                        <b-col>
                            <div v-html="$t('tarifs.majvers') + ' :'" v-if="account && account.lastOrder"/>
                            <span class="priceName">{{ price.name }}</span>
                            <span class="legende" v-html="$t('tarifs.abonnementannuel') + ' ' + $t('home.jusquau') + ' ' + dateend"></span>
                            <div v-if="account && account.lastOrder"><span class="font-weight-bold">{{ price.amount |formatNumber }}</span> <span class="devise">€</span> <span v-html="$t('tarifs.horstva')"/> </div>
                        </b-col>
                    </b-row>
                    <hr v-if="account && account.lastOrder && validationDate > 0">
                    <div v-if="price" class="text-uppercase mb-5">
                        <div class="priceName" v-if="!account || !account.lastOrder || validationDate < 0">
                            {{ price.name }} <span v-if="domainSelected" v-html="' - ' + $t('menu.'+domainSelected.title)"/>
                        </div>
                        <div v-if="$store.state.discountCode" class="font-italic">
                            Code de réduction : {{ $store.state.discountCode }}
                        </div>
                        <div>
                            <span v-html="$t('tarifs.soldearegler')"/> <span v-html="$t('tarifs.horstva')"/> =
                        </div>
                        <div class="tarif">{{ amount }} <span class="devise">€</span></div>
                        <div class="font-weight-bold mt-5"><span v-html="$t('website.tva')"/> = {{ tva }} €</div>
                        <div class="font-weight-bold mb-3">
                            <span v-html="$t('tarifs.totalttcpaiement')"/> = {{ total }} <sup>€</sup>
                        </div>
                    </div>
                    <div class="mb-5" v-if="!account">
                        <span v-html="$t('website.page_souscrire_acceptconditions')"/>
                    </div>
                </b-col>
                <b-col cols="4" v-if="price && price.amount > 0">
                    <label class="titre" v-if="card && !loading">Paiement</label>
                    <div id="card-element"/>
                    <b-button
                        class="mt-2"
                        size="sm"
                        variant="primary"
                        id="card-button"
                        @click="submitPayment"
                        v-if="card && !loading"
                    >
                        {{ $t('website.payer') }}
                    </b-button>
                    <Loading v-if="loading"/>
                    <div id="payment-result"/>
                    <b-alert variant="danger" show v-if="errorMessage" class="mt-2">{{ errorMessage }}</b-alert>
                </b-col>
            </b-row>
        </b-container>
        </b-form>
        <bottom-actions
            :button-text="total > 0 ? $t('website.page_confirmation_confirmeretpayer') : $t('website.page_confirmation_confirmer')"
            :button-text2="$t('website.page_confirmation_modifier')"
            :set-action="setAction"
            v-if="!account"
        />
    </div>
</template>

<script>
    import axios from "axios";
    import querystring from "querystring";
    import Loading from "../../../components/front/Loading";
    import BottomActions from "../../../components/footer/BottomActions";

    export default {
        name: "Payment",
        components: {BottomActions, Loading},
        data() {
            return {
                account: null,
                price: null,
                stripe: null,
                card: null,
                cs: null,
                order: null,
                loading: false,
                errorMessage: null,
                launchPayment: false,
                displayUser: null,
                discount: null
            }
        },
        computed: {
            amount() {
                let valAmount = 0
                if(this.price) {
                    valAmount = this.price.amount
                }
                if(this.account && this.account.lastOrder && this.validationDate >= 0) {
                    valAmount -= this.account.lastOrder.amount
                }
                if(this.discount) {
                    valAmount = 1*this.discount
                }
                return valAmount.toFixed(2)
            },
            total() {
                return (this.amount * this.$store.state.tva).toFixed(2)
            },
            tva() {
                return (this.total - this.amount).toFixed(2)
            },
            stripeAPIToken() {
                return this.$store.state.stripeKey
            },
            user() {
                return this.$store.state.currentUser
            },
            dateValidation() {
                if(this.account && this.account.lastOrder) {
                    return this.$moment(this.account.lastOrder.validationDate).format('DD.MM.YYYY')
                }
                return
            },
            dateend() {
                return this.$moment().add(this.$store.state.subscriptionDuration, 'M').format('DD.MM.YYYY')
            },
            domainSelected() {
                return this.$store.state.domainPrice
            },
            validationDate() {
                if(this.account && this.account.lastOrder) {
                    return this.$moment(this.account.lastOrder.validationDate).diff(this.$moment())
                }
                return
            },
        },
        mounted() {
            this.getPrice()
            this.displayUser = this.user
            if(this.$store.state.currentUser) {
                this.$getAccount(this.$store.state.apiUrl, this.$store.state.accessToken, this.$store.state.currentUser).then(
                    data => {
                        this.account = data
                        this.displayUser = data

                      if(this.$store.state.currentPrice.name === 'free') {
                        this.setOrder(null)
                      }
                    }
                ).catch(
                    //console.log('error getAccount on mount payment', this.$store.state.currentUser)
                )
            }
        },
        methods: {
            getPrice() {
                const url = this.$store.state.apiUrl + '/api/front/prices/' + this.$store.state.currentPrice.id
                axios.get(
                    url
                ).then(
                    response => {
                        this.price = response.data
                        if(this.price.amount > 0) {
                            if(this.$store.state.discountCode) {
                                this.checkCode()
                            } else {
                                this.initStripe()
                            }
                        }
                    }
                )
            },
            initStripe() {
                this.includeStripe('js.stripe.com/v3/', function(){
                    this.configureStripe();
                }.bind(this) );
            },
            includeStripe( URL, callback ){
                let documentTag = document, tag = 'script',
                    object = documentTag.createElement(tag),
                    scriptTag = documentTag.getElementsByTagName(tag)[0];
                object.src = '//' + URL;
                if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                scriptTag.parentNode.insertBefore(object, scriptTag);
            },
            configureStripe(){
                this.stripe = Stripe( this.stripeAPIToken );

                let params = {}
                params.priceId = this.price.id
                if(this.$store.state.discountCode) {
                    params.discountCode = this.$store.state.discountCode
                    params.accessToken = this.$store.state.accessToken
                }
                axios.post(
                    '/payment-intent',
                    JSON.stringify(params),
                    {
                        'headers': {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(
                    response => {
                        const elements = this.stripe.elements();
                        this.card = elements.create('card');

                        this.card.mount('#card-element');

                        this.cs = response.data.clientSecret
                    }
                )
            },
            submitPayment() {
                if(!this.launchPayment) {
                    this.launchPayment = true
                    this.stripe.confirmCardPayment(
                        this.cs, {
                            payment_method: {
                                card: this.card
                            }
                        }
                    ).then(
                        response => {
                            if(response.error) {
                                this.errorMessage = response.error.message
                            } else {
                                if(response.paymentIntent.status === "succeeded") {
                                    this.setOrder(response.paymentIntent.id)
                                }
                            }
                        }
                    )
                }
            },
            setAction(name) {
                let route
                if(name === 'buttonText') {
                    route = 'confirmation'
                }
                if(name === 'buttonText2') {
                    route = 'register'
                }
                this.$router.push({name: route, params: {lng: this.$store.state.actLng}})
            },
            setOrder(token) {
                let ref = 'C' + this.$moment().format('YYYYMMDD')
                this.addOrder(token, ref)
            },
            addOrder(token, ref) {
                let params = {}
                params.client = this.displayUser['@id']
                params.price = this.price['@id']
                params.subscriptionType = this.$store.state.subscriptionType["@id"]
                params.stripeToken = token
                params.reference = ref
                if(this.$store.state.discountCode) {
                    params.reduction = this.$store.state.discountCode
                }
                params.amount = this.total.toString()
                //params.reduction = null
                params.vatRate = this.$store.state.tva.toString()
                if(this.domainSelected) {
                    params.domain = this.domainSelected['@id']
                }
                params.state = true
                params.paymentDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
                params.validationDate = this.$moment().add(12, 'months').format('YYYY-MM-DD HH:mm:ss')

                const url = this.$store.state.apiUrl + '/api/orders'
                axios.post(
                    url,
                    params,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.postHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.order = response.data
                        this.sendEmail(ref)
                        this.$router.push({name: 'confirmation', params: {lng: this.$store.state.actLng}})
                    }
                )
            },
            checkCode() {
                let url = this.$store.state.apiUrl + '/api/discount_codes?state=1'
                url += '&code=' + this.$store.state.discountCode
                url += '&endDate[after]=' + this.$moment().format('YYYY-MM-DD')
                url += '&subscriptionType=' + this.price.id
                axios.get(
                    url
                ).then(
                    response => {
                        const code = response.data["hydra:member"][0]
                        //console.log(code)
                        this.discount = null
                        if(code) {
                            this.discount = code.value
                            this.initStripe()
                        }
                    }
                )
            },
            sendEmail(ref) {
                let params = {}
                params.email = this.displayUser.email
                params.prenom = this.displayUser.firstname
                params.nom = this.displayUser.lastname
                params.ref = ref
                params.typemontant = this.$store.state.currentPrice.name
                //console.log('send mail confirmation', params)

                const url = '/email/confirmation'
                axios.post(
                    url,
                    params,
                    {
                        'headers': {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(
                    response => {
                        this.loading = false
                        this.sent = true
                    }
                )
            },
        }
    }
</script>

<style scoped>
    #custom-button {
        height: 30px;
        outline: 1px solid grey;
        background-color: green;
        padding: 5px;
        color: white;
    }

    #card-error {
        color: red;
    }
</style>
