<template>
    <div>
        <b-card
            class="col-sm-12 col-md-6 col-lg-4"
        >
            <b-card-text>
                <b-row>
                    <b-col>Name </b-col>
                    <b-col>
                        <b-form-input size="sm" v-model="element.name" v-if="element"></b-form-input>
                        <b-form-input size="sm" v-model="newPartner.name" v-else></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>Link </b-col>
                    <b-col>
                        <b-form-input size="sm" v-model="element.url" v-if="element" maxlength="100"></b-form-input>
                        <b-form-input size="sm" v-model="newPartner.url" v-else  maxlength="100"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span v-if="element && element.image">
                            <img :src="urlApi + '/images/partners/' + element.image.filePath" alt="" style="max-width: 200px"/>
                        </span>
                        <span v-else>Image</span>
                    </b-col>
                    <b-col>
                        <b-form-file
                            v-if="element"
                            v-model="image"
                            :state="Boolean(element.image)"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                        />
                        <b-form-file
                            v-else
                            v-model="image"
                            :state="Boolean(newPartner.image)"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col cols="8">is online ?</b-col>
                            <b-col cols="4">
                                <b-icon v-if="element"
                                    :icon="element.state ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                    :variant="element.state ? 'success' : 'danger'"
                                    v-model="element.state"
                                    @click="toggle( 'state', element)"
                                />
                                <b-icon v-else
                                        :icon="newPartner.state ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                        :variant="newPartner.state ? 'success' : 'danger'"
                                        v-model="newPartner.state"
                                        @click="toggle( 'state', newPartner)"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col class="text-right mt-3">
                        <b-button size="sm" variant="primary" @click="element ? addMedia(element) : create()">save</b-button>
                        <b-button size="sm" variant="danger" @click="del(element)" v-if="element">delete</b-button>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "Partner-Admin",
        props: ["element", "priority", "getElements", "makeToast", "titleNotification"],
        data() {
            return {
                newPartner: {
                    name: null,
                    url: null,
                    state: false,
                },
                image: null,
                msg: null,
                parent: null,
            }
        },
        computed: {
            urlApi() {
                return this.$store.state.apiUrl
            }
        },
        methods: {
            toggle(element, partner) {
                partner[element] = !partner[element]
            },
            addMedia(partner) {
                if(this.image) {
                    let formData = new FormData()
                    formData.append('file', this.image)
                    const url = this.$store.state.apiUrl + '/api/media_objects'
                    axios.post(
                        url,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': 'Bearer ' + this.$store.state.accessToken
                            }
                        }
                    ).then(
                        response => {
                            partner.image = response.data["@id"]
                            this.save(partner)
                        }
                    )
                } else {
                    this.save(partner)
                }
            },
            save(partner) {
                const url = this.$store.state.apiUrl + partner["@id"]
                axios.patch(
                    url,
                    partner,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.patchHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.makeToast(partner.name + ' has been modified', 'info', this.titleNotification)
                    }
                )
            },
            create() {
                if(this.newPartner.name != null) {
                    const url = this.$store.state.apiUrl + '/api/partners'
                    this.newPartner.priority = this.priority
                    axios.post(
                        url,
                        this.newPartner,
                        {
                            'headers': {
                                'Content-Type': this.$store.state.postHeader,
                                'Authorization': 'Bearer ' + this.$store.state.accessToken
                            }
                        }
                    ).then(
                        response => {
                            this.getElements()
                            this.makeToast(this.newPartner.name + ' has been created', 'success', this.titleNotification)
                            this.newPartner.name = null
                            this.newPartner.url = null
                            this.newPartner.state = false
                            this.newPartner.image = null
                        }
                    )
                }
            },
            del(partner) {
                this.$bvModal.msgBoxConfirm('Are you sure?')
                    .then(value => {
                        if (value) {
                            const url = this.$store.state.apiUrl + partner["@id"]
                            axios.delete(
                                url,
                                {
                                    'headers': {
                                        'Authorization': 'Bearer ' + this.$store.state.accessToken
                                    }
                                }
                            ).then(
                                response => {
                                    this.getElements()
                                    this.makeToast(partner.name + ' has been deleted', 'warning', this.titleNotification)
                                }
                            )
                        }
                    })
                    .catch(err => {
                        // An error occurred
                    })
            }
        }
    }
</script>

<style scoped>
    .b-icon {
        cursor: pointer;
    }
</style>
