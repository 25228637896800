<template>
    <b-table-simple responsive="sm" class="text-center" v-if="account && country && group">
        <b-tbody>
            <b-tr>
                <b-td class="style1 font-weight-bold">
                    <span>{{ $t('countries.' + country.code) }}</span>
                </b-td>
                <b-td class="style1"><span v-html="$t('website.03_tab_delais')"/></b-td>
                <b-td class="style2"><span v-html="$t('website.03_tab_indep1')"/></b-td>
                <b-td class="style2"><span v-html="$t('website.03_tab_indep2')"/></b-td>
                <b-td class="style3"><span v-html="$t('website.03_tab_indep3')"/></b-td>
                <b-td class="style2"><span v-html="$t('website.03_tab_societe')"/></b-td>
                <b-td class="style2"><span v-html="$t('website.03_tab_specialiste')"/></b-td>
                <b-td class="style4"><span v-html="$t('website.03_tab_tauxmoy')"/></b-td>
            </b-tr>
            <b-tr>
                <b-td class="style1"><span v-html="$t('website.03_tab_gdesvilles')"/></b-td>
                <b-td class="style1" rowspan="2">
                    <div class="font-weight-bold">
                        {{ example.minDelay }} <span v-html="$t('website.a')"/> {{ example.maxDelay }}<br>
                        <span v-html="$t('website.heures')"/>
                    </div>
                    <div v-html="$t('website.03_tab_moyenne')"/>
                    <div>
                        {{ delay }} <span v-html="$t('website.heures')"/>
                    </div>
                </b-td>
                <b-td class="style2" v-for="i in 5" :key="'a'+i">
                    <rate-cell
                        :rate="rateGV[i-1]"
                        :group="group"
                        :currency="account.currency ? account.currency : currencies[0]"
                        :example="example"
                    />
                </b-td>
                <b-td class="style4" rowspan="2">
                    <rate-cell
                        :rate="moyenne"
                        :group="group"
                        :currency="account.currency ? account.currency : currencies[0]"
                        :example="example"
                        has-bold="true"
                    />
                </b-td>
            </b-tr>
            <b-tr>
                <b-td class="style1"><span v-html="$t('website.03_tab_ptvilles')"/></b-td>
                <b-td class="style2" v-for="i in 2" :key="'b'+i">
                    <rate-cell
                        :rate="ratePV[i-1]"
                        :group="group"
                        :currency="account.currency ? account.currency : currencies[0]"
                        :example="example"
                    />
                </b-td>
                <b-td class="style3">
                    <rate-cell
                        :rate="ratePV[2]"
                        :group="group"
                        :currency="account.currency ? account.currency : currencies[0]"
                        :example="example"
                    />
                </b-td>
                <b-td class="style2" v-for="i in 2" :key="'c'+i">
                    <rate-cell
                        :rate="ratePV[i+2]"
                        :group="group"
                        :currency="account.currency ? account.currency : currencies[0]"
                        :example="example"
                    />
                </b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>
    import axios from "axios";
    import DisciplineCells from "./DisciplineCells";
    import RateCell from "./RateCell";
    import currenciesJson from '../../../files/currencies.json'

    export default {
        name: "ExampleTable",
        components: {RateCell, DisciplineCells},
        props: ["example", "country", "account"],
        data() {
            return {
                currencies: currenciesJson,
                domains: null,
                group: null,
            }
        },
        mounted() {
            this.getDomains()
            this.getGroup()
        },
        watch: {
            country(newValue, oldValue) {
                this.getGroup()
            }
        },
        computed: {
            delay() {
                return (this.example.minDelay + this.example.maxDelay)/2
            },
            rateGV() {
                return this.example.topic.domain.rates[0]
            },
            ratePV() {
                return this.example.topic.domain.rates[1]
            },
            moyenne() {
                return this.example.topic.domain.moyenne
            },
        },
        methods: {
            getDomains() {
                this.$store.commit("setDomains", true)

                //const url = '../files/domains.json'
                const url = this.$store.state.apiUrl + '/api/front/domains?menu=1'
                axios.get(
                    url
                ).then(
                    response => {
                        this.domains = response.data["hydra:member"]
                    }
                )
            },
            getGroup() {
                let tabId = [1]
                if(this.country && this.country.group) {
                    if(this.country.group["@id"]) {
                        tabId = this.country.group["@id"].split('/')
                    } else {
                        tabId = this.country.group.split('/')
                    }
                }
                const url = this.$store.state.apiUrl + '/api/front/group/' + tabId[tabId.length -1]
                axios.get(
                    url
                ).then(
                    response => {
                        this.group = response.data
                    }
                )
            }
        }
    }
</script>

<style scoped>
    .table th, .table td {
        border: 0;
    }
    .table thead th {
        vertical-align: top;
        border-bottom: 0;
    }
    .table .cells {
        padding: 0;
    }
    .style1 {
        background-color: #e7e5e6;
        vertical-align: middle;
    }
    .style2 {
        background-color: #f4f4f4;
        vertical-align: middle;
    }
    .style3 {
        background-color: #d0e5f8;
        vertical-align: middle;
    }
    .style4 {
        background-color: #d2ebf2;
        vertical-align: middle;
    }
</style>
