<template>
    <div>
        <b-card
            class="col-sm-12 col-md-6 col-lg-4"
        >
            <b-card-text>
                <b-row>
                    <b-col>Name </b-col>
                    <b-col>
                        <b-input-group size="sm" :append="$t('cession.' + element.title)" v-if="element" maxlength="30">
                            <b-form-input size="sm" v-model="element.title"/>
                        </b-input-group>
                        <b-form-input size="sm" v-model="newElement.title" v-else  maxlength="30"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>Ratio </b-col>
                    <b-col>
                        <b-form-input size="sm" v-model="element.ratio" v-if="element" maxlength="30"></b-form-input>
                        <b-form-input size="sm" v-model="newElement.ratio" v-else  maxlength="30"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>Copyright type </b-col>
                    <b-col>
                        <b-form-select size="sm" v-model="element.type['@id']" class="mb-3" v-if="element">
                            <b-form-select-option v-for="type in types" :key="type.id" :value="type['@id']">{{ type.title }}</b-form-select-option>
                        </b-form-select>
                        <b-form-select size="sm" v-model="newElement.type" class="mb-3" v-else>
                            <b-form-select-option v-for="type in types" :key="type.id" :value="type['@id']">{{ type.title }}</b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-right mt-3">
                        <b-button size="sm" variant="primary" @click="element ? save(element) : create()">save</b-button>
                        <b-button size="sm" variant="danger" @click="del(element)" v-if="element">delete</b-button>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Factor-Admin",
    props: ["element", "url", "getElements", "makeToast", "titleNotification"],
    data() {
        return {
            newElement: {
                title: null,
                ratio: null,
                type: null,
            },
            types: null,
            msg: null,
            parent: null,
        }
    },
    mounted() {
        this.getTypes()
    },
    methods: {
        getTypes() {
            const url = this.$store.state.apiUrl + '/api/copyright_types?state=true&pagination=false'
            axios.get(
                url,
                {
                    'headers': {
                        'Authorization': 'Bearer ' + this.$store.state.accessToken
                    }
                }
            ).then(
                response => {
                    this.types = response.data["hydra:member"]
                }
            )
        },
        toggle(field, element) {
            element[field] = !element[field]
        },
        save(element) {
            const url = this.$store.state.apiUrl + element["@id"]
            let updateElement = {
                title: element.title,
                ratio: element.ration,
                type: element.type["@id"]
            }
            axios.patch(
                url,
                updateElement,
                {
                    'headers': {
                        'Content-Type': this.$store.state.patchHeader,
                        'Authorization': 'Bearer ' + this.$store.state.accessToken
                    }
                }
            ).then(
                response => {
                    this.makeToast(element.title + ' has been modified', 'info', this.titleNotification)
                }
            )
        },
        create() {
            if(this.newElement.title != null) {
                const url = this.$store.state.apiUrl + '/api/' + this.url
                axios.post(
                    url,
                    this.newElement,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.postHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.getElements()
                        this.makeToast(this.newElement.title + ' has been created', 'success', this.titleNotification)
                        this.newElement.title = null
                        this.newElement.ratio = null
                    }
                )
            }
        },
        del(element) {
            this.$bvModal.msgBoxConfirm('Are you sure?')
                .then(value => {
                    if (value) {
                        const url = this.$store.state.apiUrl + element["@id"]
                        axios.delete(
                            url,
                            {
                                'headers': {
                                    'Authorization': 'Bearer ' + this.$store.state.accessToken
                                }
                            }
                        ).then(
                            response => {
                                this.getElements()
                                this.makeToast(element.title + ' has been deleted', 'warning', this.titleNotification)
                            }
                        )
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        }
    }
}
</script>

<style scoped>
.b-icon {
    cursor: pointer;
}
</style>
