<template xmlns="http://www.w3.org/1999/html">
    <div>
        <div v-if="reset">
            <b-container>
                <div class="titre mb-2 mt-5" v-html="$t('lostpwd.reset-done')"></div>
                <hr>
            </b-container>
        </div>
        <div v-else-if="error">
            <b-container>
                <div class="titre mb-2 mt-5" v-html="$t('lostpwd.reset-error')"></div>
                <hr>
            </b-container>
        </div>
        <div v-else>
            <validation-observer ref="observer" v-slot="{ handleSubmit }" v-if="existsToken">
                <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
                    <b-container>
                        <div class="titre mb-2 mt-5" v-html="$t('lostpwd.reset-explain')"></div>
<!--                        <div class="mb-2 mt-5" v-html="$t('lostpwd.reset-explain')"></div>-->
                        <hr>
                        <b-row class="mb-1" v-for="(field, index) in fields" :key="index" v-if="field.show">
                            <b-col sm="3">
                                <label v-html="$t('website.page_souscrire_'+field.name)"/>
                            </b-col>
                            <b-col sm="6">
                                <validation-provider
                                    :name="field.key"
                                    :rules="field.rule"
                                    v-slot="validationContext"
                                >
                                    <b-form-group :id="'example-input-group-'+index">
                                        <b-input-group size="sm">
                                            <b-form-input
                                                :id="'input-small-'+index"
                                                :name="'input-small-'+index"
                                                v-model="form[field.key]"
                                                :state="getValidationState(validationContext)"
                                                :aria-describedby="'input-'+index+'-live-feedback'"
                                                size="sm"
                                                type="password"
                                            ></b-form-input>
                                        </b-input-group>
                                        <b-form-invalid-feedback :id="'input-'+index+'-live-feedback'">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </b-row>
                    </b-container>
                    <bottom-actions
                        :button-text="$t('website.valider')"
                        :set-action="setAction"
                    />
                </b-form>
            </validation-observer>
            <div v-else>
                <b-container>
                    <div class="titre mb-2 mt-5" v-html="$t('lostpwd.expired')"></div>
                    <hr>
                </b-container>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import BottomActions from "../../../components/footer/BottomActions";

    export default {
        name: "Recovery",
        data() {
            return {
                form: {
                    password: null
                },
                existsToken: null,
                fields: [
                    {name: 'mdp', key: 'password', show: true, rule: 'required|confirmed:password'},
                    {name: 'confirmation', key: 'confirmation', show: true, rule: 'required|confirmed:password'},
                ],
                lostPassword: null,
                reset: null,
                error: null
            }
        },
        mounted() {
            this.checkToken()
        },
        components: {BottomActions},
        methods: {
            setAction(name) {
                this.buttonName = name
            },
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
            checkToken() {
                let params = {}
                params.token = this.$route.params.token
                const url = '/email/check-token'
                axios.post(
                    url,
                    params,
                    {
                        'headers': {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(
                    response => {
                        if(response.data["hydra:totalItems"] > 0) {
                            this.existsToken = true
                            this.lostPassword = response.data["hydra:member"][0]
                        } else {
                            this.existsToken = false
                        }
                    }
                )
            },
            onSubmit() {
                let params = {}
                params.password = this.form.password
                params.token = this.$route.params.token
                const url = '/user/update-password'
                axios.post(
                    url,
                    params,
                    {
                        'headers': {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(
                    response => {
                        if(response.data === "done") {
                            this.reset = true
                        } else {
                            this.reset = false
                            if(response.data === "error") {
                                this.error = true
                            } else {
                                this.error = false
                            }
                        }
                    }
                )
            }
        }
    }
</script>

<style scoped>
    .stitre {
        font-weight: bold;
        color: #000000;
        font-size: 15px !important;
        line-height: 17px;
    }
</style>
