<template>
    <b-tr cols="12">
        <b-td class="text-nowrap">{{ title }}</b-td>
        <b-td class="text-right text-nowrap nopad">
            <b-input-group class="flex-nowrap" size="sm" :append="$t('website.heures')">
                <b-form-input
                    v-model="modelH"
                    style="width: 25px"
                    @change="changeValue"
                />
            </b-input-group>
        </b-td>
        <b-td class="text-right text-nowrap nopad">
            <b-input-group class="flex-nowrap" size="sm" :append="account.currency ? account.currency.symbol : '€'">
                <b-form-input
                    v-model="modelR"
                    style="width: 35px"
                    @change="changeValue"
                />
            </b-input-group>
        </b-td>
        <b-td class="text-right text-nowrap">
            {{ totalHonoraire |formatNumber }} <span v-html="account.currency ? account.currency.symbol : '€'"/>
        </b-td>
    </b-tr>
</template>

<script>
    import {serverBus} from "../../../../app";

    export default {
        name: "CalculCell",
        props: ['example', 'account', 'index', 'title', 'name', 'currency', 'number'],
        data() {
            return {
                modelH: 0,
                modelR: 0,
                th: 0,
            }
        },
        mounted() {
            this.initialValue()
            serverBus.$on('figure', (bool) => {
                this.initialValue()
            })

            this.getValuesHonoraire()
            this.setValuesHonoraire()
        },
        computed: {
            figure() {
                return this.$store.state.figure
            },
            totalHonoraire() {
                this.tH = this.modelH * this.modelR
                return this.tH
            },
        },
        watch: {
            figure(newValue, oldValue) {
                if(!this.$store.state.figure) {
                    this.modelH = 0
                    this.modelR = 0
                } else {
                    this.initialValue()
                    this.setValuesHonoraire()
                }
                // /return this.$store.state.figure
            },
        },
        methods: {
            initialValue() {
                if(this.$store.state.figure) {
                    const delay = (this.example.minDelay + this.example.maxDelay) / 2
                    const hours = delay / 7
                    const moyenne = this.example.topic.domain.moyenne
                    this.modelH = Math.round(this.number * hours)
                    this.modelR = (this.account.hourRate)
                        ? this.account.hourRate
                        : (this.account.currency)
                            ? Math.round(100 * moyenne * this.account.country.group.coef * this.account.currency.rate)/100
                            : Math.round(100 * moyenne * this.account.country.group.coef)/100
                }
            },
            getValuesHonoraire() {
                let calcul = this.$store.state.calcul
                if (calcul && calcul[this.name] && calcul[this.name][this.index]) {
                    const calculObject = calcul[this.name][this.index]
                    if (calculObject['hour']) {
                        this.modelH = calculObject['hour']
                        this.modelR = calculObject['rate']
                    }
                }
            },
            setValuesHonoraire() {
                let calcul = this.$store.state.calcul
                if(!calcul) {
                    calcul = {}
                }
                if(!calcul[this.name]) {
                    calcul[this.name] = {}
                }
                if(!calcul[this.name][this.index]) {
                    calcul[this.name][this.index] = {}
                }
                calcul[this.name][this.index]['hour'] = 1*this.modelH
                calcul[this.name][this.index]['rate'] = 1*this.modelR
                calcul[this.name][this.index]['value'] = this.tH

                this.$store.commit('setElement', {'key': 'calcul', 'value': calcul})
            },
            changeValue() {
                this.setValuesHonoraire()
                serverBus.$emit(this.name, true)
            },
        }

    }
</script>

<style scoped>
    .nopad {
        padding-top: 5px;
        padding-bottom: 0;
    }
</style>
