<template>
    <validation-observer ref="observer" v-slot="{ handleSubmit }" v-if="account">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <b-container class="page">
                <b-row class="titre mb-2">
                    <div v-html="$t('website.basepourundevis')"/>
                </b-row>
                <b-row class="stitre font-weight-bold">
                    <div v-html="$t('domaines.' + example.topic.domain.title) + ' :'"/>
                    <div v-html="' ' + $t('domaines.' + example.topic.title)" class="ml-1"/>
                </b-row>
                <b-row class="mb-2">
                    <div v-html="$t('examples.' + example.title)"/>
                </b-row>

                <b-row class="mt-4 topic">
                    <b-col cols="12" class="font-weight-bold stitre ucfirst mt-2 mb-3">
                        {{ $t('website.honorairescreation').toLowerCase() }}
                    </b-col>
                    <b-col cols="12" v-for="(honoraire, index) in calcul.honoraires" :key="index">
                        <b-row>
                            <b-col cols="10">
                                <span v-html="1*index+1"/>.
                                <span class="ucfirst">{{ $t('website.'+honoraires[index]).toLowerCase() }}</span>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                {{ (honoraire.hour*honoraire.rate) |formatNumber }} {{ account.currency ? account.currency.symbol : '€' }}
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" class="ucfirst mb-3">
                        {{ $t('website.delainormal').toLowerCase() }} {{ delai }} {{ $t('website.jours') }}
                    </b-col>
                    <b-col cols="12" class="mb-2 font-weight-bold">
                        <b-row>
                            <b-col cols="9"/>
                            <b-col cols="2" class="ucfirst text-right">
                                {{ $t('website.totalcalculhorstaxe').toLowerCase() }} =
                            </b-col>
                            <b-col cols="1" class="text-right">
                                {{ totalHonoraires |formatNumber }} {{ account.currency ? account.currency.symbol : '€' }}
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <b-row class="mt-4 topic" v-if="calcul.exploitation">
                    <b-col cols="12" class="font-weight-bold stitre ucfirst mt-2 mb-3">
                        {{ $t('website.04_p01_titre').toLowerCase() }}
                    </b-col>
                    <b-col cols="12" class="mb-3">
                        <div class="ucfirst">{{ $t('website.05_oeuvre_nonvendue').toLowerCase() }}</div>
                        <div class="ucfirst">{{ $t('website.utilisation').toLowerCase() }} :
                            <span v-for="(exploit, index) in calcul.exploitation" :key="index">
                                {{ $t('cession.'+exploit.model.title).toLowerCase() }}
                                <span v-if="index < Object.keys(calcul.exploitation).length-1">, </span>
                            </span>
                        </div>
                        <div class="ucfirst" v-html="$t('website.06red_reproduiterepresentee').toLowerCase()"/>
                    </b-col>
                    <b-col cols="12" class="mb-2 font-weight-bold">
                        <b-row>
                            <b-col cols="9"/>
                            <b-col cols="2" class="ucfirst text-right">
                                {{ $t('website.totalcalculhorstaxe').toLowerCase() }} =
                            </b-col>
                            <b-col cols="1" class="text-right">
                                {{ totalExploitation |formatNumber }} {{ account.currency ? account.currency.symbol : '€' }}
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <b-row class="mt-4 topic" v-if="calcul.redevance">
                    <b-col cols="12" class="font-weight-bold stitre ucfirst mt-2 mb-3">
                        {{ $t('website.04_p03_titre').toLowerCase() }}
                    </b-col>
                    <b-col cols="12" class="mb-3">
                        <div class="ucfirst">{{ $t('website.06red_oeuvrevendue').toLowerCase() }}</div>
                        <div class="ucfirst">{{ $t('website.utilisation').toLowerCase() }} :
                            <span v-html="$t('website.'+redevance[calcul.redevance.rights])"/>
                        </div>
                        <div class="ucfirst" v-html="$t('website.06red_reproduiterepresentee').toLowerCase()"/>
                    </b-col>
                </b-row>

                <b-row class="mt-4 topic" align-h="end">
                    <b-col cols="12" class="font-weight-bold stitre ucfirst mt-2 mb-3">
                        {{ $t('website.06red_totaldevis').toLowerCase() }}
                    </b-col>
                    <b-col cols="3">
                        <droit-total-cell
                            :currency="account.currency ? account.currency.symbol : '€'"
                            :total="total"
                            :account="account"
                        />
                    </b-col>
                </b-row>

                <b-row class="mt-4 topic" v-if="calcul.option">
                    <b-col cols="12" class="font-weight-bold stitre ucfirst mt-2 mb-3">
                        <div v-html="$t('website.04_p02_option').toLowerCase()"/>
                    </b-col>
                    <b-col cols="12" class="mb-2">
                        <b-row>
                            <b-col cols="10">
                                <div class="ucfirst">
                                    {{ $t('website.04_p02_cout').toLowerCase() }}
                                    {{ $t('website.04_sur' + calcul.option2 + 'ans').toLowerCase() }} :
                                    + {{ calcul.option[0].rate }}% {{ $t('website.05_surtotaldroits').toLowerCase() }}
                                </div>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                {{ (calcul.option[0].value) |formatNumber }} {{ account.currency ? account.currency.symbol : '€' }}
                            </b-col>
                        </b-row>
                        <b-row v-for="i in 2" :key="i">
                            <b-col cols="10">
                                <div class="ucfirst">
                                    {{ (calcul.option[i].rate) }}%  {{ $t('website.04_p02_annee'+i).toLowerCase() }}
                                </div>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                {{ (calcul.option[i].value) |formatNumber }} {{ account.currency ? account.currency.symbol : '€' }}
                            </b-col>
                        </b-row>
                        <b-row v-if="Object.keys(calcul.option).length > 2">
                            <b-col cols="10">
                                <div class="ucfirst">
                                    {{ $t('website.04_p02_anneex').toLowerCase() }}
                                </div>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                {{ (calcul.option[3].value) |formatNumber }} {{ account.currency ? account.currency.symbol : '€' }}
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <b-row class="mt-4 topic" v-if="calcul.option" align-h="end">
                    <b-col cols="12" class="font-weight-bold stitre ucfirst mt-2">
                        <div v-html="$t('website.05_totaldevis_echelonnement').toLowerCase()"/>
                    </b-col>
                    <b-col cols="3">
                        <droit-total-cell
                            :currency="account.currency ? account.currency.symbol : '€'"
                            :total="totalOption"
                            :account="account"
                        />
                    </b-col>
                </b-row>

                <b-row class="mt-4 topic" v-if="calcul.option" align-h="end">
                    <b-col cols="12" class="font-weight-bold stitre ucfirst mt-2 mb-3">
                        <div v-html="$t('website.05_totaldevis_1reannee_echelonnement').toLowerCase()"/>
                    </b-col>
                    <b-col cols="3">
                        <droit-total-cell
                            :currency="account.currency ? account.currency.symbol : '€'"
                            :total="totalOption1"
                            :account="account"
                        />
                    </b-col>
                </b-row>

                <b-row class="mb-3 mt-2 font-weight-bold" v-if="!calcul.exploitation">
                    <div v-html="$t('website.04_devis_prixsansdroits')"/>
                </b-row>
                <b-row class="mb-2">
                    <div v-html="$t('website.textedevis')"/>
                </b-row>
            </b-container>
        </b-form>
    </validation-observer>
</template>

<script>
    import DroitTotalCell from "../../../components/front/droits/DroitTotalCell";
    export default {
        name: "Devis",
        components: {DroitTotalCell},
        data() {
            return {
                account: null,
                honoraires: [
                    'recherchepremierprojet',
                    'misepointprojetretenu',
                    'executioncreation',
                ],
                options: [
                    '04_p02_cout',
                    '04_p02_annee1',
                    '04_p02_annee2',
                    '04_p02_anneex'
                ],
                redevance: [
                    '06red_typeutilisation_exclusive',
                    '06red_typeutilisation_nonexclusive'
                ],
            }
        },
        computed: {
            lng() {
                return this.$store.state.actLng
            },
            user() {
                return this.$store.state.currentUser
            },
            example() {
                return this.$store.state.example
            },
            calcul() {
                return this.$store.state.calcul
            },
            delai() {
                let delai = 0
                if(this.calcul.honoraires) {
                    for (let i = 0; i < Object.keys(this.calcul.honoraires).length; i++) {
                        delai += Math.round(this.calcul.honoraires[i].hour / 4) / 2
                    }
                }
                return delai
            },
            totalHonoraires() {
                let total = 0
                if(this.calcul.honoraires) {
                    for (let i = 0; i < Object.keys(this.calcul.honoraires).length; i++) {
                        total += this.calcul.honoraires[i].hour * this.calcul.honoraires[i].rate
                    }
                }
                return total
            },
            totalExploitation() {
                let total = 0
                if(this.calcul.exploitation) {
                    for(let i = 0; i < Object.keys(this.calcul.exploitation).length; i++) {
                        total += this.calcul.exploitation[i].value
                    }
                }
                return total

            },
            totalOption() {
                return this.total + this.calcul.option[0].value
            },
            totalOption1() {
                return this.totalHonoraires + this.calcul.option[1].value
            },
            total() {
                return this.totalHonoraires + this.totalExploitation
            },
            tva() {
                let tva = 0
                if(this.account.vatRate) {
                    tva = this.account.vatRate/100
                }
                return Math.round(100*tva*this.total)/100
            },
            ttc() {
                return this.total+this.tva
            }
        },
        mounted() {
            if(!this.$store.state.currentUser) {
                this.$router.push({name: 'home', params: {lng: this.$store.state.actLng}})
            }

            this.$getAccount(this.$store.state.apiUrl, this.$store.state.accessToken, this.$store.state.currentUser).then(
                data => {
                    this.account = data
                    this.$checkAccessPage(this.account, this.example)
                }
            )
            this.$store.commit("setDomainStep", 5)
        },
    }
</script>

<style scoped>
    .ucfirst::first-letter {
        text-transform: uppercase;
    }
</style>
