@font-face {
    font-family: 'calkulator';
    src: url('./fonts/calkulator.eot');
    src: url('./fonts/calkulator.eot?#iefix') format('embedded-opentype'),
    url('./fonts/calkulator.woff') format('woff'),
    url('./fonts/calkulator.ttf') format('truetype'),
    url('./fonts/calkulator.svg#calkulator') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
    display: inline-block;
    font-family: 'calkulator';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
[class*='icon-']{
    font-size: 30px;
    color: #009e99;
}
.icon-ICOcomputer:before{content:'\0041';}
.icon-ICOfee:before{content:'\0042';}
.icon-ICOflatrate:before{content:'\0043';}
.icon-ICOroyalties:before{content:'\0044';}
.icon-ICOchat:before{content:'\0045';}
.icon-ICOcheck:before{content:'\0046';}
.icon-ICOworld:before{content:'\0047';}
.icon-ICOwinwin:before{content:'\0048';}
.icon-ICOconsole:before{content:'\0049';}
.icon-ICOdisciplines:before{content:'\004a';}
.icon-ICOcountries:before{content:'\004b';}
.icon-ICOcompare:before{content:'\004c';}
.icon-ICOunite:before{content:'\004e';}

.icon-ICOwinwin {
    float: left !important;
    font-size: 90px !important;
    color: #009e99 !important;
    margin-right: 10px;
}
.icon-ICOdisciplines {
    font-size: 32px;
    float: left;
}
.icon-ICOworld {
    font-size: 40px;
    color: #fff;
}
.icon-ICOcomputer {
    font-size: 25px;
}
