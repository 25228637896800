<template>
    <b-container fluid>
        <h5><b-badge variant="primary">{{ name }}</b-badge></h5>

        <component v-if="name" v-bind:is="name" name="name" :make-toast="makeToast"/>
    </b-container>
</template>

<script>
    import Clients from "./users/Clients";
    import Domains from "./domains/Domains";
    import Examples from "./domains/Examples";
    import Prices from "./config/Prices";
    import Topics from "./domains/Topics";

    export default {
        name: "List-Admin",
        props: ["makeToast"],
        components: {Clients, Domains, Examples, Prices, Topics},
        computed: {
            name() {
                return this.$route.meta.name
            }
        }
    }
</script>

<style scoped>
    .badge {
        text-transform: capitalize;
    }
</style>
