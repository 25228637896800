<template>
    <b-row>
        <b-col
            cols="12" sm="12" md="6" lg="2"
            class="mb-4"
            v-for="(domain, index) in domains"
            :key="domain.id"
        >
            <b-card
                :header="$t('domaines.'+domain.title)"
                :style="'color: '+domain.color"
            >
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                    <b-form @submit.stop.prevent="handleSubmit(setDomain(domain))">
                        <b-card-text>
                            <b-row class="mb-3">
                                <b-col cols="12">
                                    <b-form-input size="sm" v-model="domain.title"/>
                                </b-col>
                            </b-row>
                            <b-row class="mb-3">
                                <b-col cols="12">
                                    <b-input-group size="sm" prepend="Taux">
                                        <b-form-input v-model="domain.rate"/>
                                    </b-input-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="7">
                                    Menu <b-icon :icon="domain.menu ? 'bookmark-check' : 'bookmark-x'" font-scale="2" v-model="domain.menu" @click="toogleMenu(domain)"/> Droits <b-icon :icon="domain.restricted ? 'bookmark-check' : 'bookmark-x'" font-scale="2" @click="toogleRestricted(domain)"/>
                                </b-col>
                                <b-col cols="2">
                                    <verte picker="square" model="hex" v-model="domain.color"></verte>
                                </b-col>
                                <b-col class="text-right">
                                    <b-button size="sm" type="submit" variant="primary">save</b-button>
                                </b-col>
                            </b-row>
                        </b-card-text>
                    </b-form>
                </validation-observer>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    import axios from "axios"
    import verte from 'verte'
    import 'verte/dist/verte.css'
    import querystring from "querystring";
    import domainsJson from '../../../../files/domains.json'

    export default {
        name: "Domains-Admin",
        components: {verte},
        props: ["name", "makeToast"],
        data() {
            return {
                domains: null,
                titleNotification: 'Domain Notification',
            }
        },
        mounted() {
            this.getDomains()
        },
        methods: {
            getDomains() {
                const url = this.$store.state.apiUrl + '/api/domains'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.domains = response.data["hydra:member"]
                    }
                )
            },
            toogleMenu(domain) {
                domain.menu = !domain.menu
                this.setDomain(domain)
            },
            toogleRestricted(domain) {
                domain.restricted = !domain.restricted
                this.setDomain(domain)
            },
            setDomain(domain) {
                const url = this.$store.state.apiUrl + domain["@id"]
                axios.patch(
                    url,
                    domain,
                    {
                        'headers': {
                            'Content-type': this.$store.state.patchHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.makeToast(domain.title + ' has been modified', 'info', this.titleNotification)
                        this.generate()
                    }
                )
            },
            generate() {
                const url = '/generate/domains'
                axios.post(
                    url,
                    querystring.stringify(
                        {
                            domains: JSON.stringify(this.domains)
                        }
                    )
                ).then(
                    response => {
                    }
                )
            },
        }
    }
</script>

<style scoped>
    .b-icon {
        cursor: pointer;
    }
</style>
