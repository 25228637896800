<template>
    <div class="mt-3">
        <b-form-select v-model="content_keys" v-if="content_keys && element">
            <b-form-select-option v-for="contentKey in content_keys" :key="contentKey.id" :value="contentKey.name">{{ contentKey.name }}</b-form-select-option>
        </b-form-select>
        <b-tabs content-class="mt-3">
            <b-tab
                lazy
                v-for="contentKey in content_keys"
                :key="contentKey.id"
                :title="contentKey.name"
                v-if="content_keys && element"
            >
                <content-key :contentKey="contentKey" :contentType="element" :get-content-keys="getContentKeys" :make-toast="makeToast"/>
                <translations :contentKey="contentKey" :make-toast="makeToast"/>
            </b-tab>
            <b-tab title="add a key">
                <content-key :contentType="element" :get-content-keys="getContentKeys" :make-toast="makeToast"/>
            </b-tab>

        </b-tabs>
    </div>
</template>

<script>
    import axios from "axios";
    import ContentKey from "../../../components/admin/ContentKey";
    import Translations from "../../../components/admin/Translations";

    export default {
        name: "ContentKeys-Admin",
        components: {ContentKey, Translations},
        props: ["element", "getContentTypes", "makeToast"],
        data() {
            return {
                content_keys: null,
            }
        },
        mounted() {
           this.getContentKeys()
        },
        methods: {
            getContentKeys() {
                const url = this.$store.state.apiUrl + '/api/content_keys?contentType=' + this.element["@id"] + '&pagination=false'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.content_keys = response.data["hydra:member"]
                    }
                )
            },
        }
    }
</script>

<style scoped>
    .b-icon {
        cursor: pointer;
    }
</style>
