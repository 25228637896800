<template>
    <div>
        <b-card
            class="col-sm-12 col-md-6 col-lg-4"
        >
            <b-card-text>
                <b-row>
                    <b-col>Name </b-col>
                    <b-col>
                        <b-form-input size="sm" v-model="element.name" v-if="element" maxlength="30"></b-form-input>
                        <b-form-input size="sm" v-model="newOption.name" v-else  maxlength="30"></b-form-input>
                        <div v-if="element && element.name" v-html="$t('tarifs.'+element.name)"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>Type </b-col>
                    <b-col>
                        <b-form-select size="sm" v-model="element.type" :options="options" class="mt-3" v-if="element"></b-form-select>
                        <b-form-select size="sm" v-model="newOption.type" :options="options" class="mt-3" v-else></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>Icon Name </b-col>
                    <b-col>
                        <b-form-input size="sm" v-model="element.icon" v-if="element" maxlength="30"></b-form-input>
                        <b-form-input size="sm" v-model="newOption.icon" v-else  maxlength="30"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>Background Color </b-col>
                    <b-col>
                        <b-form-input size="sm" v-model="element.color" v-if="element" maxlength="30"></b-form-input>
                        <b-form-input size="sm" v-model="newOption.color" v-else  maxlength="30"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col cols="8">is bold ?</b-col>
                            <b-col cols="4">
                                <b-icon v-if="element"
                                        :icon="element.isBold ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                        :variant="element.isBold ? 'success' : 'danger'"
                                        v-model="element.isBold"
                                        @click="toggle( 'isBold', element)"
                                />
                                <b-icon v-else
                                        :icon="newOption.isBold ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                        :variant="newOption.isBold ? 'success' : 'danger'"
                                        v-model="newOption.isBold"
                                        @click="toggle( 'isBold', newOption)"
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="8">is online ?</b-col>
                            <b-col cols="4">
                                <b-icon v-if="element"
                                    :icon="element.state ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                    :variant="element.state ? 'success' : 'danger'"
                                    v-model="element.state"
                                    @click="toggle( 'state', element)"
                                />
                                <b-icon v-else
                                        :icon="newOption.state ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                        :variant="newOption.state ? 'success' : 'danger'"
                                        v-model="newOption.state"
                                        @click="toggle( 'state', newOption)"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col class="text-right mt-3">
                        <b-button size="sm" variant="primary" @click="element ? save(element) : create()">save</b-button>
                        <b-button size="sm" variant="danger" @click="del(element)" v-if="element">delete</b-button>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "Option-Admin",
        props: ["element", "priority", "getElements", "makeToast"],
        data() {
            return {
                newOption: {
                    name: null,
                    icon: null,
                    color: null,
                    isBold: false,
                    state: false,
                },
                options: [
                    { value: 'name', text: 'text'},
                    { value: 'number', text: 'number'},
                    { value: 'state', text: 'yes/no'},
                    { value: 'amount', text: 'amount'},
                ],
                msg: null,
                parent: null,
                titleNotification: "Option Notification",
            }
        },
        methods: {
            toggle(element, option) {
                option[element] = !option[element]
            },
            save(element) {
                const url = this.$store.state.apiUrl + element["@id"]
                axios.patch(
                    url,
                    element,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.patchHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.makeToast(element.name + ' has been modified', 'info', this.titleNotification)
                    }
                )
            },
            create() {
                if(this.newOption.name != null) {
                    const url = this.$store.state.apiUrl + '/api/options'
                    axios.post(
                        url,
                        this.newOption,
                        {
                            'headers': {
                                'Content-Type': this.$store.state.postHeader,
                                'Authorization': 'Bearer ' + this.$store.state.accessToken
                            }
                        }
                    ).then(
                        response => {
                            this.getElements()
                            this.makeToast(this.newOption.name + ' has been created', 'success', this.titleNotification)
                            this.newOption.name = null
                            this.newOption.icon = null
                            this.newOption.color = null
                            this.newOption.isBold = false
                            this.newOption.state = false
                        }
                    )
                }
            },
            del(element) {
                this.$bvModal.msgBoxConfirm('Are you sure?')
                    .then(value => {
                        if (value) {
                            const url = this.$store.state.apiUrl + element["@id"]
                            axios.delete(
                                url,
                                {
                                    'headers': {
                                        'Authorization': 'Bearer ' + this.$store.state.accessToken
                                    }
                                }
                            ).then(
                                response => {
                                    this.getElements()
                                    this.makeToast(element.name + ' has been deleted', 'warning', this.titleNotification)
                                }
                            )
                        }
                    })
                    .catch(err => {
                        // An error occurred
                    })
            }
        }
    }
</script>

<style scoped>
    .b-icon {
        cursor: pointer;
    }
</style>
