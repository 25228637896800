<template>
    <b-navbar toggleable="lg" type="dark" variant="info">
        <b-container fluid>
            <b-navbar-brand href="#"><div class="logo">CalKulator <span class="version">4.3</span></div></b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav v-if="pseudo">
                <b-navbar-nav>
                    <b-nav-item-dropdown text="Users">
                        <b-dropdown-item><router-link :to="{ name: 'admin-clients' }">Clients</router-link></b-dropdown-item>
                        <b-dropdown-item><router-link :to="{ name: 'admin-partners' }">Partners</router-link></b-dropdown-item>
                        <b-dropdown-item><router-link :to="{ name: 'admin-discount-codes' }">Discount Codes</router-link></b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown text="Domains">
                        <b-dropdown-item><router-link :to="{ name: 'admin-domains' }">Domains</router-link></b-dropdown-item>
                        <b-dropdown-item><router-link :to="{ name: 'admin-topics' }">Topics</router-link></b-dropdown-item>
                        <b-dropdown-item><router-link :to="{ name: 'admin-examples' }">Examples</router-link></b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown text="Config">
                        <b-dropdown-item><router-link :to="{ name: 'admin-price-options' }">Price Options</router-link></b-dropdown-item>
                        <b-dropdown-item><router-link :to="{ name: 'admin-prices' }">Prices</router-link></b-dropdown-item>
                        <b-dropdown-item><router-link :to="{ name: 'admin-options' }">Options</router-link></b-dropdown-item>
                        <b-dropdown-item><router-link :to="{ name: 'admin-profils' }">Profils types</router-link></b-dropdown-item>
                        <b-dropdown-item><router-link :to="{ name: 'admin-activities' }">Activities types</router-link></b-dropdown-item>
                        <b-dropdown-item><router-link :to="{ name: 'admin-currencies' }">Currencies</router-link></b-dropdown-item>
                        <b-dropdown-item><router-link :to="{ name: 'admin-countries' }">Countries</router-link></b-dropdown-item>
                        <b-dropdown-item><router-link :to="{ name: 'admin-countries-groups' }">Countries groups</router-link></b-dropdown-item>
                        <b-dropdown-item><router-link :to="{ name: 'admin-copyright-factors' }">Copyright factors</router-link></b-dropdown-item>
                        <b-dropdown-item><router-link :to="{ name: 'admin-copyright-types' }">Copyright types</router-link></b-dropdown-item>
                        <b-dropdown-item><router-link :to="{ name: 'admin-criteria' }">Criteria</router-link></b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown text="Content">
                        <b-dropdown-item><router-link :to="{ name: 'admin-translations' }">Translations</router-link></b-dropdown-item>
                        <b-dropdown-item><router-link :to="{ name: 'admin-languages' }">Languages</router-link></b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <b-nav-item right>
                        <b-avatar button variant="light" @click="logout" :text="pseudo.preferred_username"/>
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-container>
    </b-navbar>

</template>

<script>
    import {serverBus} from "../../../app";

    export default {
        name: "MenuAdmin",
        props: ["makeToast"],
        computed: {
            pseudo() {
                return this.$store.state.currentUser
            }
        },
        methods: {
            logout() {
                serverBus.$emit('logout', true)
            }
        }
    }
</script>

<style scoped>
    .bg-info {
        background-color: #073036 !important;
    }
</style>
