<template>
    <b-table-simple striped>
        <b-tbody>
            <calcul-cell
                :index="i-1"
                :title="i+'. ' + $t('website.'+honoraires[i-1]['title'])"
                :number="honoraires[i-1]['number']"
                :example="example"
                :account="account"
                name="honoraires"
                v-for="i in 3"
                :key="i"
            />
            <b-tr>
                <b-td><span v-html="$t('website.03_p02_total')"/></b-td>
                <b-td
                    colspan="3"
                    class="text-right font-weight-bold"
                >
                    {{ totalHonoraires |formatNumber }} <span v-html="account.currency ? account.currency.symbol : '€'"/>
                </b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>
    import axios from "axios";
    import DisciplineCells from "../DisciplineCells";
    import RateCell from "../RateCell";
    import CalculCell from "./CalculCell";
    import {serverBus} from "../../../../app";
    import store from "../../../../store";

    export default {
        name: "ExampleCalcul",
        components: {CalculCell, RateCell, DisciplineCells},
        props: ["example", "account"],
        data() {
            return {
                domains: null,
                group: null,
                honoraires: [
                    {
                        title: 'recherchepremierprojet',
                        number: 4
                    },
                    {
                        title: 'misepointprojetretenu',
                        number: 2
                    },
                    {
                        title: 'executioncreation',
                        number: 1
                    },
                ],
                totalHonoraires: 0
            }
        },
        mounted() {
            this.checkCalculs()
            serverBus.$on('honoraires', (isChanged) => {
                if(isChanged) {
                    this.checkCalculs()
                    serverBus.$emit('honoraires', false)
                }
            })
        },
        computed: {
           /* total() {
                const tH = 1*this.$store.state.calcul.hon + 1*this.$store.state.h2 + 1*this.$store.state.h3
                this.$store.commit('setElement', {'key': 'tH', 'value': tH})
                return tH
            }*/
        },
        methods: {
            getDomains() {
                this.$store.commit("setDomainStep", 1)
                this.$store.commit("setDomains", true)

                //const url = '../files/domains.json'
                const url = this.$store.state.apiUrl + '/api/front/domains?menu=1'
                axios.get(
                    url
                ).then(
                    response => {
                        this.domains = response.data["hydra:member"]
                    }
                )
            },
            getGroup() {
                let tabId
                if(this.country.group["@id"]) {
                    tabId = this.country.group["@id"].split('/')
                } else {
                    tabId = this.country.group.split('/')
                }
                const url = this.$store.state.apiUrl + '/api/front/group/' + tabId[tabId.length -1]
                axios.get(
                    url
                ).then(
                    response => {
                        this.group = response.data
                    }
                )
            },
            checkCalculs() {
                let calcul = this.$store.state.calcul
                if(calcul && calcul['honoraires']) {
                    this.totalHonoraires = 0
                    Object.keys(calcul['honoraires']).forEach((key, index) => {
                        const calculObject = calcul['honoraires'][index]
                        if(calculObject['hour']) {
                            this.totalHonoraires += calculObject['hour']*calculObject['rate']
                        }
                    })
                }
            },
        }
    }
</script>

<style scoped>
    .table th, .table td {
        border: 0;
    }
    .table thead th {
        vertical-align: top;
        border-bottom: 0;
    }
    .table .cells {
        padding: 0;
    }
    .style1 {
        background-color: #e7e5e6;
        vertical-align: middle;
    }
    .style2 {
        background-color: #f4f4f4;
        vertical-align: middle;
    }
    .style3 {
        background-color: #d0e5f8;
        vertical-align: middle;
    }
    .style4 {
        background-color: #d2ebf2;
        vertical-align: middle;
    }
</style>
