<template>
    <div class="top">
        <div class="container">
            <div class="row contentTop">
                <div class="col-sm-12 col-md-4"><logo/></div>
                <div class="col-sm-12 col-md-8 connecte"><login/></div>
            </div>
        </div>
    </div>
</template>

<script>
    import Logo from "./Logo";
    import Login from "./Login";
    export default {
        name: "Top",
        components: {Login, Logo}
    }
</script>

<style scoped>
    .container {
        max-width: 1210px !important;
    }
    .top{
        color: #fff;
        background: #2f7d7c; /* Old browsers */
        background: -moz-linear-gradient(top,  #2f7d7c 0%, #245a5e 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#2f7d7c), color-stop(100%,#245a5e)); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top,  #2f7d7c 0%,#245a5e 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top,  #2f7d7c 0%,#245a5e 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top,  #2f7d7c 0%,#245a5e 100%); /* IE10+ */
        background: linear-gradient(to bottom,  #2f7d7c 0%,#245a5e 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2f7d7c', endColorstr='#245a5e',GradientType=0 ); /* IE6-9 */
    }
    .contentTop {
        min-height: 52px;
        color: #FFFFFF;
        line-height: 15px;
        max-width: 1200px !important;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .connecte, .connecte a {
        padding-top: 5px;
        color: #ccffff;
        font-size: 12px;
        line-height: 15px;
    }
</style>
