<template>
    <div>
        <b-card
            class="col-sm-12 col-md-6 col-lg-4"
        >
            <b-card-text>
                <b-row>
                    <b-col>Name </b-col>
                    <b-col>
                        <b-form-input size="sm" v-model="element.name" v-if="element" maxlength="30"></b-form-input>
                        <b-form-input size="sm" v-model="newElement.name" v-else  maxlength="30"></b-form-input>
                        <div v-if="element && element.name && $t('website.'+element.name) !== 'website.'+element.name" v-html="$t('website.'+element.name)"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col cols="8">is online ?</b-col>
                            <b-col cols="4">
                                <b-icon v-if="element"
                                        :icon="element.state ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                        :variant="element.state ? 'success' : 'danger'"
                                        v-model="element.state"
                                        @click="toggle( 'state', element)"
                                />
                                <b-icon v-else
                                        :icon="newElement.state ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                        :variant="newElement.state ? 'success' : 'danger'"
                                        v-model="newElement.state"
                                        @click="toggle( 'state', newElement)"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col class="text-right mt-3">
                        <b-button size="sm" variant="primary" @click="element ? save(element) : create()">save</b-button>
                        <b-button size="sm" variant="danger" @click="del(element)" v-if="element">delete</b-button>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "NameState-Admin",
    props: ["element", "url", "getElements", "makeToast", "titleNotification"],
    data() {
        return {
            newElement: {
                name: null,
                state: false,
            },
            msg: null,
            parent: null,
        }
    },
    methods: {
        toggle(field, element) {
            element[field] = !element[field]
        },
        save(element) {
            const url = this.$store.state.apiUrl + element["@id"]
            axios.patch(
                url,
                element,
                {
                    'headers': {
                        'Content-Type': this.$store.state.patchHeader,
                        'Authorization': 'Bearer ' + this.$store.state.accessToken
                    }
                }
            ).then(
                response => {
                    this.getElements()
                    this.makeToast(element.name + ' has been modified', 'info', this.titleNotification)
                }
            )
        },
        create() {
            if(this.newElement.name != null) {
                const url = this.$store.state.apiUrl + '/api/' + this.url
                axios.post(
                    url,
                    this.newElement,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.postHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.getElements()
                        this.makeToast(this.newElement.name + ' has been created', 'success', this.titleNotification)
                        this.newElement.name = null
                        this.newElement.state = false
                    }
                )
            }
        },
        del(element) {
            this.$bvModal.msgBoxConfirm('Are you sure?')
                .then(value => {
                    if (value) {
                        const url = this.$store.state.apiUrl + element["@id"]
                        axios.delete(
                            url,
                            {
                                'headers': {
                                    'Authorization': 'Bearer ' + this.$store.state.accessToken
                                }
                            }
                        ).then(
                            response => {
                                this.getElements()
                                this.makeToast(element.name + ' has been deleted', 'warning', this.titleNotification)
                            }
                        )
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        }
    }
}
</script>

<style scoped>
.b-icon {
    cursor: pointer;
}
</style>
