<template>
<div>
        <b-card
            class="col-sm-12 col-md-6 col-lg-4"
        >
            <b-card-text>
                <b-row>
                    <b-col>Title </b-col>
                    <b-col>
                        <b-input-group size="sm" :append="$t('criteres.' + element.title)" v-if="element">
                            <b-form-input size="sm" v-model="element.title"/>
                        </b-input-group>
                        <b-form-input size="sm" v-model="newCriterion.title" v-else  maxlength="30"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>Domain </b-col>
                    <b-col v-if="domains">
                        <b-form-select size="sm" v-model="element.domain" class="mb-3" v-if="element">
                            <b-form-select-option v-for="domain in domains" :key="domain.id" :value="domain['@id']">
                                {{ $t('domaines.' + domain.title) }}
                            </b-form-select-option>
                        </b-form-select>
                        <b-form-select size="sm" v-model="newCriterion.domain" class="mb-3" v-else>
                            <b-form-select-option v-for="domain in domains" :key="domain.id" :value="domain['@id']">
                                {{ $t('domaines.' + domain.title) }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>Color </b-col>
                    <b-col>
                        <verte picker="square" model="hex" v-model="element.color" v-if="element"></verte>
                        <verte picker="square" model="hex" v-model="newCriterion.color" v-else></verte>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col cols="8">is online ?</b-col>
                            <b-col cols="4">
                                <b-icon v-if="element"
                                        :icon="element.state ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                        :variant="element.state ? 'success' : 'danger'"
                                        v-model="element.state"
                                        @click="toggle( 'state', element)"
                                />
                                <b-icon v-else
                                        :icon="newCriterion.state ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                        :variant="newCriterion.state ? 'success' : 'danger'"
                                        v-model="newCriterion.state"
                                        @click="toggle( 'state', newCriterion)"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col class="text-right mt-3">
                        <b-button size="sm" variant="primary" @click="element ? save(element) : create()">save</b-button>
                        <b-button size="sm" variant="danger" @click="del(element)" v-if="element">delete</b-button>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
    import axios from "axios"
    import verte from 'verte'
    import 'verte/dist/verte.css'

    export default {
        name: "Criterion-Admin",
        components: {verte},
        props: ["element", "getElements", "makeToast"],
        data() {
            return {
                newCriterion: {
                    name: null,
                    contentType: null,
                    domain: null
                },
                domains: null,
                msg: null,
                parent: null,
                titleNotification: 'Criterion Notification',
            }
        },
        mounted() {
            this.getDomains()
        },
        methods: {
            toggle(element, price) {
                price[element] = !price[element]
            },
            getDomains() {
                const url = this.$store.state.apiUrl + '/api/domains'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.domains = response.data["hydra:member"]
                    }
                )
            },
            save(criterion) {
                const url = this.$store.state.apiUrl + criterion["@id"]
                axios.patch(
                    url,
                    criterion,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.patchHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.makeToast(criterion.title + ' has been modified', 'info', this.titleNotification)
                    }
                )
            },
            create() {
                if(this.newCriterion.title != null) {
                    //this.newCriterion.contentType = this.contentType["@id"]
                    const url = this.$store.state.apiUrl + '/api/criteria'
                    axios.post(
                        url,
                        this.newCriterion,
                        {
                            'headers': {
                                'Content-Type': this.$store.state.postHeader,
                                'Authorization': 'Bearer ' + this.$store.state.accessToken
                            }
                        }
                    ).then(
                        response => {
                            this.getElements()
                            this.makeToast(this.newCriterion.title + ' has been created', 'success', this.titleNotification)
                            this.newCriterion.title = null
                        }
                    )
                }
            },
            del(criterion) {
                this.$bvModal.msgBoxConfirm('Are you sure?')
                    .then(value => {
                        if (value) {
                            const url = this.$store.state.apiUrl + criterion["@id"]
                            axios.delete(
                                url,
                                {
                                    'headers': {
                                        'Authorization': 'Bearer ' + this.$store.state.accessToken
                                    }
                                }
                            ).then(
                                response => {
                                    this.getElements()
                                    this.makeToast(criterion.title + ' has been deleted', 'warning', this.titleNotification)
                                }
                            )
                        }
                    })
                    .catch(err => {
                        // An error occurred
                    })
            }
        }
    }
</script>

<style scoped>
    .b-icon {
        cursor: pointer;
    }
</style>
