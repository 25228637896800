<template>
    <div>
        <router-link :to="{ name: 'home', params: {lng: lng} }">
            <div class="logo">{{ $t('website.logo') }} <span class="version">{{ $t('website.version') }}</span></div>
            <div class="baseline" v-if="!admin">{{ $t('website.baseline') }}</div>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "Logo",
        computed: {
            admin() {
                return this.$store.state.admin
            },
            lng() {
                return this.$store.state.actLng
            },
        },
    }
</script>

<style scoped>
    a:hover,
    a {
        color: #FFFFFF;
        text-decoration: none;
    }
    .logo {
        color: #FFFFFF;
        font-size: 26px;
        font-family: "ff-meta-web-pro", "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
        letter-spacing: 2px;
        margin-top: 10px;
    }
    .version {
        font-size: 21px;
    }
    .baseline {
        font-size: 15px;
        font-family: "ff-meta-web-pro", "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
        word-spacing: 1px;
        letter-spacing: normal;
        margin-top: 6px;
    }
</style>
