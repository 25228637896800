body {
    background-color: #fff;
    background-repeat: repeat-x;
    font-family: "ff-meta-web-pro", Verdana, Arial, Helvetica, sans-serif;
    line-height: 17px;
    font-size: 13px;
    padding: 0;
    margin: 0;
}
a:hover {
    text-decoration: none;
}

a.success {
    color: #28a745 !important;
}
a.danger {
    color: #e66d74 !important;
}

.sublog {
    background-color: #009e99 !important;
    border: 0 !important;
    border-radius: 2px !important;
    color: #ccffff !important;
    cursor: pointer !important;
    padding: 2px 10px 4px 10px !important;
    height: 23px !important;
    font-size: 12px;
    font-family: "ff-meta-web-pro", Verdana, Arial, Helvetica, sans-serif;
    text-transform: capitalize;
}

.priceName {
    text-transform: uppercase;
    font-weight: bold;
    color: #009e99;
}

.titre {
    font-size: 18px;
    line-height: 22px;
    color: #009e99;
}

.tarif {
    font-size: 55px;
    color: #000;
    margin: 20px 0;
    font-weight: normal;
}
.poste {
    background-color: #f5f5f5;
    min-height: 35px;
    padding: 6px 5px;
    padding-top: 10px;
}
.discipline {
    background-color: #d8effd;
    /*min-height: 65px;*/
    height: 100%;
    padding: 6px 5px;
    padding-top: 10px;
}
.droit {
    background-color: #cdf1ef;
    /*min-height: 55px;*/
    height: 100%;
    padding: 6px 5px;
    padding-top: 10px;
}
.assistance {
    background-color: #b9feff;
    /*min-height: 55px;*/
    height: 100%;
    padding: 10px 5px;
}
.blocGreenBottom {
    background-color: #eaf5f7;
    border-radius: 3px 3px 0 0;
    padding: 0;
}
.blocGreen {
    background-color: #ecf6f6;
    border-radius: 3px 3px 0 0;
    text-align: center;
}
.navig {
    color: #008b8b;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 1px;
    padding: 15px;
}
.navig a {
    color: #008b8b;
    text-decoration: none;
}
.navig.actif a {
    color: #000;
    font-weight: bold;
}

.page {
    padding: 50px;
    font-size: 13px;
    line-height: 17px;
}
.topic {
    font-size: 13px;
    padding: 4px;
    background-color: #f5f5f5;
    margin-bottom: 14px;
}
.calcul .stitre, .topic .titre {
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    padding: 5px;
    color: #000;
    padding-right: 14px;
}

.my-tooltip {
    color: red !important;
    border: 1px solid red !important;
    background-color: white !important;
    border-radius: 2px !important;
    padding: 2px 10px !important;
    font-size: 13px;
}
.my-tooltip .arrow {
    display: none;
}

.my-tooltip .tooltip-inner {
    color: red !important;
    max-width: none !important;
    background-color: transparent !important;
}

.free {
    color: #ff0000;
    font-size: 12px;
}


.tarifs .table {
    width: 1180px;
    border-spacing: 4px 0;
    border-collapse: separate !important;
    background-color: #eaf5f7 !important;
}

.calcul .table {
    width: 1080px;
    border-spacing: 4px 4px;
    border-collapse: separate;
    margin-bottom: 0;
}

.tarifs .table tr, .tarifs .table thead {
    background-color: #fff;
}

.tarifs .table thead tr th {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 0;
    vertical-align: top;
}

.tarifs .table td {
    border-top: 0;
    padding: 2px 10px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    height: 100%;
}
.tarifs .table tbody tr:last-child td {
    border-bottom: 1px solid #ccc;
}

.tarifs .table tbody tr td {
    border-bottom: 2px solid #fff;
}
.calculLine {
    background-color: #f4f4f4;
    padding: 0.5625rem 0.625rem;
}
.calculTotal {
    padding: 0.5625rem 0.625rem;
}
