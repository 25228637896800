<template>
    <div>
        <b-card
            class="col-sm-12 col-md-6 col-lg-4"
        >
            <b-card-text>
                <b-row>
                    <b-col>Code </b-col>
                    <b-col>
                        <b-input-group size="sm" :append="$t('countries.' + element.code)" v-if="element">
                            <b-form-input size="sm" v-model="element.code" maxlength="2"/>
                        </b-input-group>
                        <b-form-input size="sm" v-model="newElement.code" v-else  maxlength="2"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>VAT Code </b-col>
                    <b-col>
                        <b-form-input size="sm" v-model="element.vatCode" v-if="element" maxlength="3"/>
                        <b-form-input size="sm" v-model="newElement.vatCode" v-else  maxlength="3"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>Country group </b-col>
                    <b-col>
                        <b-form-select size="sm" v-model="element.group" v-if="element">
                            <b-form-select-option :value="null">{{ $t('website.choisir') }}</b-form-select-option>
                            <b-form-select-option
                                v-for="group in groups"
                                :key="group.id"
                                :value="group['@id']"
                            >
                                {{ $t('groups.' + (group.title)) }}
                            </b-form-select-option>
                        </b-form-select>
                        <b-form-select size="sm" v-model="newElement.group" v-else>
                            <b-form-select-option :value="null">{{ $t('website.choisir') }}</b-form-select-option>
                            <b-form-select-option
                                v-for="group in groups"
                                :key="group.id"
                                :value="group['@id']"
                            >
                                {{ $t('groups.' + (group.title)) }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col cols="8">is ue ?</b-col>
                            <b-col cols="4">
                                <b-icon v-if="element"
                                        :icon="element.ue ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                        :variant="element.ue ? 'success' : 'danger'"
                                        v-model="element.ue"
                                        @click="toggle( 'ue', element)"
                                />
                                <b-icon v-else
                                        :icon="newElement.ue ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                        :variant="newElement.ue ? 'success' : 'danger'"
                                        v-model="newElement.ue"
                                        @click="toggle( 'ue', newElement)"
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="8">is online ?</b-col>
                            <b-col cols="4">
                                <b-icon v-if="element"
                                        :icon="element.state ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                        :variant="element.state ? 'success' : 'danger'"
                                        v-model="element.state"
                                        @click="toggle( 'state', element)"
                                />
                                <b-icon v-else
                                        :icon="newElement.state ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                        :variant="newElement.state ? 'success' : 'danger'"
                                        v-model="newElement.state"
                                        @click="toggle( 'state', newElement)"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col class="text-right mt-3">
                        <b-button size="sm" variant="primary" @click="element ? save(element) : create()">save</b-button>
                        <b-button size="sm" variant="danger" @click="del(element)" v-if="element">delete</b-button>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
    import axios from "axios";
    import groupsJson from '../../../files/groups.json'

    export default {
        name: "Country-Admin",
        props: ["element", "url", "getElements", "makeToast", "titleNotification"],
        data() {
            return {
                groups: groupsJson,
                newElement: {
                    code: null,
                    vatCode: null,
                    state: false,
                    group: null,
                },
                msg: null,
                parent: null,
            }
        },
        methods: {
            toggle(field, element) {
                element[field] = !element[field]
            },
            save(element) {
                const url = this.$store.state.apiUrl + element["@id"]
                axios.patch(
                    url,
                    element,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.patchHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.getElements()
                        this.makeToast(element.code + ' has been modified', 'info', this.titleNotification)
                    }
                )
            },
            create() {
                if(this.newElement.code != null) {
                    const url = this.$store.state.apiUrl + '/api/' + this.url
                    axios.post(
                        url,
                        this.newElement,
                        {
                            'headers': {
                                'Content-Type': this.$store.state.postHeader,
                                'Authorization': 'Bearer ' + this.$store.state.accessToken
                            }
                        }
                    ).then(
                        response => {
                            this.getElements()
                            this.makeToast(this.newElement.code + ' has been created', 'success', this.titleNotification)
                            this.newElement.code = null
                            this.newElement.state = false
                        }
                    )
                }
            },
            del(element) {
                this.$bvModal.msgBoxConfirm('Are you sure?')
                    .then(value => {
                        if (value) {
                            const url = this.$store.state.apiUrl + element["@id"]
                            axios.delete(
                                url,
                                {
                                    'headers': {
                                        'Authorization': 'Bearer ' + this.$store.state.accessToken
                                    }
                                }
                            ).then(
                                response => {
                                    this.getElements()
                                    this.makeToast(element.code + ' has been deleted', 'warning', this.titleNotification)
                                }
                            )
                        }
                    })
                    .catch(err => {
                        // An error occurred
                    })
            }
        }
    }
</script>

<style scoped>
.b-icon {
    cursor: pointer;
}
</style>
